import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../common/enums/AuthProtect';
import { userFiltersSearch } from 'interfaces/userinfo.interface';
import { setParams } from 'helpers/functions';

export const getPalmettoUsersEndpoint = (filters: userFiltersSearch): IEndpoint => {
  const params = setParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `usersPalmetto`,
    params: params
  };
};
