import { createSlice } from '@reduxjs/toolkit';
import { archivePlanThunk } from './archivePlanThunk';
import { QueryState } from 'common/enums';
import { ArchivePlanEmptyState, IArchivePlanState } from './archivePlanState';

const initialState: IArchivePlanState = ArchivePlanEmptyState;

export const archivePlan = createSlice({
  name: 'archivePlan',
  initialState,
  reducers: {
    resetArchivePlanState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(archivePlanThunk.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(archivePlanThunk.fulfilled, (state, action) => {
        state.state = QueryState.UPDATED;
      })
      .addCase(archivePlanThunk.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetArchivePlanState } = archivePlan.actions;
export default archivePlan.reducer;
