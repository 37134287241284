import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { IUserMitigation } from 'interfaces/userinfo.interface';

export interface IFetchUsersState {
  state: QueryState;
  users: IUserMitigation[] | [];
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const FetchUsersEmptyState: IFetchUsersState = {
  state: QueryState.UNDEFINED,
  users: [],
  error: null,
  accessToken: undefined
};
