import React from 'react';
import { FormHelperText, Box, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { NormalTextField, PrimaryButton } from '@zawarski/palmetto-ui-components';
import { NextButton } from 'components/primitives/Button';
import 'common/styles/common.css';
import '@zawarski/palmetto-ui-components/dist/styles/common.login.css';

import { LoginProps } from './Login.props';
import { Info } from '../../components/primitives';

const LoginView: React.FC<LoginProps> = ({ errMessage, login, ...props }: LoginProps) => {
  const loginDisabled = !login.username || !login.password;

  const mitigationLogin = (
    <div className="layout vertical center vertical-center full-height">
      <div className="layout vertical logincontainer">
        <div className="layout horizontal center center-justified app-logo-container">
          <img
            // sizing="contain"
            className="app-logo"
            src={process.env.REACT_APP_APP_IMAGE}
            alt="Palmetto Logo"
          />
        </div>

        <div className="layout horizontal center-justified">
          <span className="app-title">{process.env.REACT_APP_APP_TITLE}</span>
        </div>

        <div className="flex login-input-container">
          <div className="layout horizontal">
            <NormalTextField
              id="username-box"
              label="Username"
              name="username"
              placeholder="Enter username"
              className="flex"
              value={login?.username || ''}
              onChange={props.handleChange}
              margin="normal"
              // InputLabelProps={{
              //   shrink: true
              // }}
            />
          </div>

          <div className="layout horizontal">
            <NormalTextField
              id="password-box"
              label="Password"
              name="password"
              type="password"
              placeholder="Enter password"
              className="flex"
              value={login?.password || ''}
              onChange={props.handleChange}
              margin="normal"
              // InputLabelProps={{
              //   shrink: true
              // }}
            />
          </div>

          {errMessage && (
            <FormHelperText sx={{ mt: 1, lineHeight: '12px' }} error>
              {errMessage}
            </FormHelperText>
          )}

          <NextButton
            sx={{ mt: errMessage ? 2 : 3 }}
            disabled={loginDisabled}
            onClick={props.handleLogin}
            fullWidth>
            Login
          </NextButton>
        </div>

        <div className="layout horizontal center center-justified request-account-section">
          <span>Don’t have an account? </span> &nbsp;{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={process.env.REACT_APP_PALMETTO_ENDPOINT + '/#/requestaccount'}
            className="bottom-link">
            Request access
          </a>
        </div>
      </div>
      <div className="layout horizontal center center-justified request-account-section">
        All data in Mitigation SC is to be considered FOUO and not for public dissimination.
      </div>
    </div>
  );

  const portalRedirect = (
    <>
      {props.loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            backgroundColor: 'rgb(128 128 128 / 31%)',
            zIndex: 99,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }}>
          <Stack rowGap={2}>
            <CircularProgress size={32} sx={{ mx: 'auto' }} />
            <Typography variant="body1" fontWeight={400} color="textSecondary">
              {'Verifying token...'}
            </Typography>
          </Stack>
        </Box>
      )}
      {!props.loading && (
        <div className="layout vertical center vertical-center full-height">
          <div className="layout vertical logincontainer">
            <div className="layout horizontal center center-justified app-logo-container">
              <img className="app-logo" src="/images/launcher.png" alt="Palmetto Logo" />
            </div>
            <div className="layout horizontal center-justified">
              <span className="app-title">{process.env.REACT_APP_APP_TITLE}</span>
            </div>
            <div
              style={{
                textAlign: 'center',
                height: '50px',
                paddingTop: '24px',
                color: 'rgba(0,0,0,67)',
                fontSize: '14px'
              }}>
              Please use the Portal to access this site.
            </div>
            <div className="layout horizontal center-justified">
              <PrimaryButton
                className="flex login-btn"
                style={{ maxWidth: '220px' }}
                onClick={() => {
                  window.location.href = 'https://portal.palmettoeoc.com';
                }}>
                NAVIGATE TO PORTAL
              </PrimaryButton>
            </div>
            <div
              style={{
                textAlign: 'center',
                paddingLeft: '64px',
                paddingRight: '64px',
                paddingTop: '44px',
                color: 'rgba(107,107,107,30)',
                fontSize: '14px'
              }}>
              Access to this site is now available only through the portal on the following link:{' '}
            </div>
            <div
              style={{ fontSize: '16px', paddingTop: '4px' }}
              className="layout horizontal center-justified">
              <a target="_blank" rel="noopener noreferrer" href="https://portal.palmettoeoc.com">
                portal.palmettoeoc.com
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
  // return mitigationLogin;
  return portalRedirect;
};

export default LoginView;
