import React from 'react';
import { DropzoneDialog } from 'react-mui-dropzone';
import { UploadFileDialogProps } from './UploadFileDialog.props';

const UploadFileDialog: React.FC<UploadFileDialogProps> = (props) => {
  return (
    <DropzoneDialog
      {...props}
      maxFileSize={props?.maxFileSize || 1099511627776}
      cancelButtonText={props?.cancelButtonText || 'Cancel'}
      submitButtonText={props?.submitButtonText || 'Upload'}
    />
  );
};

export default UploadFileDialog;
