import { MdInfoOutline } from 'react-icons/md';
import React from 'react';
import { InfoProps } from './Info.props';
const Info: React.FC<InfoProps> = ({ message }: InfoProps) => {
  return (
    <div className="layout vertical vertical-center full-height">
      <span>
        <MdInfoOutline
          style={{ color: 'rgba(0, 0, 0, 0.8)', width: '100%', height: '100%' }}
          size={52}
        />
      </span>
      <div className="height-20"></div>
      <span style={{ padding: '0 16px' }} className="text-center title-big opacity-54">
        {message}
      </span>
    </div>
  );
};

export default Info;
