import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';

export interface ILocalFilesState {
  state: QueryState;
  parentID: Nullable<string>;
  dataset: Nullable<any>;
  datasetByFolder: Nullable<any>;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const LocalFilesEmptyState: ILocalFilesState = {
  state: QueryState.UNDEFINED,
  parentID: null,
  dataset: null,
  datasetByFolder: null,
  error: null,
  accessToken: undefined
};
