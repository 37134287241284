import DefaultPaletteOptions from 'theming/defaultPalette';

export default (palette: DefaultPaletteOptions): any => {
  const inputHeight = '40px';
  return {
    styleOverrides: {
      root: {
        margin: '0 !important',
        paddingRight: 0,
        height: inputHeight,
        backgroundColor: '#fafafa',
        '&.MuiInputBase-inputAdornedEnd': {
          paddingRight: 0
        },
        '& .MuiInputAdornment-positionEnd': {
          position: 'absolute',
          right: '12px'
        },
        '& .MuiInputBase-input:not(.MuiInputBase-inputMultiline)': {
          paddingTop: 0,
          paddingBottom: 0,
          height: inputHeight,
          fontSize: '14px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#F5F7FA'
        },
        '& .Mui-disabled ': {
          borderColor: '#F5F7FA !important'
        },
        '& .MuiSvgIcon-root': {
          color: '#D8D8D8'
        },
        '&.MuiInputBase-multiline': {
          paddingTop: 0,
          paddingBottom: 0,
          height: 'auto',
          '& .MuiInputBase-input': {
            paddingTop: '10px !important',
            fontSize: '14px'
          }
        }
      }
    }
  };
};
