import { createSlice } from '@reduxjs/toolkit';
import { CreateProjectEmptyState, ICreateProjectState } from './createProjectState';
import { QueryState } from 'common/enums';
import { createProject } from './createProjectThunk';

const initialState: ICreateProjectState = CreateProjectEmptyState;

export const createProjectSlice = createSlice({
  name: 'createProject',
  initialState,
  reducers: {
    resetCreateProjectState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createProject.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(createProject.fulfilled, (state) => {
        state.state = QueryState.CREATED;
      })
      .addCase(createProject.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetCreateProjectState } = createProjectSlice.actions;
export default createProjectSlice.reducer;
