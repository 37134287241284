import { createSlice } from '@reduxjs/toolkit';
import { QueryState } from 'common/enums';
import { DeleteRequirementEmptyState, IDeleteRequirementState } from './deleteRequirementState';
import { deleteRequirement } from './deleteRequirementThunk';

const initialState: IDeleteRequirementState = DeleteRequirementEmptyState;

export const deleteRequirementSlice = createSlice({
  name: 'deleteRequirement',
  initialState,
  reducers: {
    resetDeleteRequirementState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(deleteRequirement.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(deleteRequirement.fulfilled, (state) => {
        state.state = QueryState.DELETED;
      })
      .addCase(deleteRequirement.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetDeleteRequirementState } = deleteRequirementSlice.actions;
export default deleteRequirementSlice.reducer;
