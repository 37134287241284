import React from 'react';
import { Box, Typography } from '@mui/material';
import { ISimpleBadge } from './SimpleBadge.props';

export const SimpleBadgeView = (props: ISimpleBadge) => {
  return (
    <Box
      sx={{
        display: 'inline-block',
        minWidth: '0',
        paddingLeft: '8px',
        margin: '4px 0'
      }}>
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: '700',
          color: '#252525',
          padding: '4px 12px',
          borderRadius: '550px',
          backgroundColor: 'rgba(37, 37, 37, 0.04)',
          border: '1px solid rgba(0, 0, 0, 0.04)'
        }}>
        {props.text}
      </Typography>
    </Box>
  );
};
