import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { fetchProjectsAndTypesEndpoint } from 'services/projectsEndPoints';
import { IProjectDetails } from 'interfaces/project.interface';
import { IManageColumns } from 'interfaces/project.interface';

export const fetchProjectsAndTypesThunk = createAsyncThunk(
  'fetchProjectTypes',
  async (filters: IManageColumns) => {
    try {
      const endpoint = fetchProjectsAndTypesEndpoint(filters);
      const resp = await apiFetch<{ dataset: IProjectDetails[] }>(endpoint).then((res) => {
        return res?.data || null;
      });
      return resp.dataset;
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
