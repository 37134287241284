import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from 'common/enums/AuthProtect';
import { IFileInfo, IFileUpdate } from 'interfaces/File';

export const getLocalFiles: (query: string) => IEndpoint = (query) => ({
  authorization: AuthProtect.REQUIRED,
  url: `files${query}`,
  method: 'GET'
});

export const createFile: (data: IFileInfo) => IEndpoint = (data) => ({
  authorization: AuthProtect.REQUIRED,
  url: 'files',
  method: 'POST',
  data
});

export const updateFiles: (data: IFileUpdate[]) => IEndpoint = (data) => ({
  authorization: AuthProtect.REQUIRED,
  url: 'files',
  method: 'PUT',
  data
});

export const deleteFileEndpoint: (id: string) => IEndpoint = (id) => ({
  authorization: AuthProtect.REQUIRED,
  url: `file/${id}`,
  method: 'DELETE'
});
