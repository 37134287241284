import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { deleteRequirementEndpoint } from 'services/checklistEndPoints';

export const deleteRequirement = createAsyncThunk('deleteRequirement', async (id: string) => {
  try {
    const endpoint = deleteRequirementEndpoint(id);
    return await apiFetch<any>(endpoint).then((res) => res?.data || null);
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
