import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateOrganizations } from './updatePlanOrganizationThunk';
import { QueryState } from 'common/enums';
import { IOrganizationsState, OrganizationsEmptyState } from './updatePlanOrganizationState';
import { IUpdateOrganization } from 'interfaces/plan.interface';
const initialState: IOrganizationsState = OrganizationsEmptyState;

export const updateOrganizationsSlice = createSlice({
  name: 'updateOrganizations',
  initialState,
  reducers: {
    updateOrganizations: (state, action: PayloadAction<IUpdateOrganization>) => {
      state.organizations = action.payload;
    },
    resetUpdateOrganizationsState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(updateOrganizations.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(updateOrganizations.fulfilled, (state, action) => {
        state.state = QueryState.UPDATED;
      })
      .addCase(updateOrganizations.rejected, (state, action) => {
        state.state = QueryState.FAIL;
      });
  }
});

export const { resetUpdateOrganizationsState } = updateOrganizationsSlice.actions;
export default updateOrganizationsSlice.reducer;
