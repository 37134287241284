import React from 'react';
import theme from 'theming/theme';
import useStyles from './MainLayout.styles';
import Box from '@mui/material/Box';
import { MainLayoutProps } from './MainLayout.props';

const MainLayoutView: React.FC<MainLayoutProps> = (props) => {
  const style = useStyles(theme);

  return (
    <Box sx={style.wrapper}>
      {props.toolbar}
      <Box sx={[style.page, props.styles ? props.styles : null]}>{props.children}</Box>
    </Box>
  );
};

export default MainLayoutView;
