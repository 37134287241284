import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProjectDetails } from 'interfaces/project.interface';
import apiFetch from 'services/apiFetch';
import { fetchAllProjectsEndpoint } from 'services/projectsEndPoints';

export const fetchAllProjectsThunk = createAsyncThunk('fetchAllProjects', async () => {
  try {
    const endpoint = fetchAllProjectsEndpoint();
    const resp = await apiFetch<{ count: number; dataset: IProjectDetails[] }>(endpoint).then(
      (res) => res?.data || null
    );
    return resp;
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
