import { createSlice } from '@reduxjs/toolkit';
import { UpdateHomeTextEmptyState, IUpdateHomeTextState } from './updateHomeTextState';
import { updateHomeText } from './updateHomeTextThunk';
import { QueryState } from 'common/enums';

const initialState: IUpdateHomeTextState = UpdateHomeTextEmptyState;

export const updateHomeTextSlice = createSlice({
  name: 'updateHomeText',
  initialState,
  reducers: {
    resetUpdateHomeTextState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(updateHomeText.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(updateHomeText.fulfilled, (state, action) => {
        state.state = QueryState.UPDATED;
      })
      .addCase(updateHomeText.rejected, (state, action) => {
        state.state = QueryState.FAIL;
      });
  }
});

export const { resetUpdateHomeTextState } = updateHomeTextSlice.actions;
export default updateHomeTextSlice.reducer;
