//COGNITO
// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { UserAuthEmptyState, ITokenState } from './TokenState';
//
// const initialState: ITokenState = UserAuthEmptyState;
//
// export const authSlice = createSlice({
//   name: 'token',
//   initialState,
//   reducers: {
//     resetTokenState: () => {
//       return initialState;
//     },
//     updateToken: (state, action: PayloadAction<ITokenState>) => {
//       return action.payload;
//     }
//   }
// });
//
// export const { resetTokenState, updateToken } = authSlice.actions;
//
// export default authSlice.reducer;

//PALMETTO ACCOUNT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITokenState, UserAuthEmptyState, UserInfoAuth } from './TokenState';
import { userLogin } from './tokenThunk';

const initialState: ITokenState = UserAuthEmptyState;

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<UserInfoAuth>) => {
      state.user = action.payload;
    },
    updateTokenState: (state, action: PayloadAction<ITokenState>) => {
      return action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(userLogin.fulfilled, (state, action) => {
        state.authorization = action.payload?.authorization;
        state.createdAt = action.payload?.createdAt;
        state.ttl = action.payload?.ttl;
        state.user = action.payload?.user;
      })
      .addCase(userLogin.rejected, () => {
        return initialState;
      });
  }
});

export const { updateUser, updateTokenState } = tokenSlice.actions;

export default tokenSlice.reducer;
