import { createSlice } from '@reduxjs/toolkit';
import { IFetchPlanState, FetchPlanEmptyState } from './fetchPlanByIdState';
import { fetchPlanById } from './fetchPlanByIdThunk';
import { QueryState } from 'common/enums';

const initialState: IFetchPlanState = FetchPlanEmptyState;

export const fetchPlanByIdSlice = createSlice({
  name: 'fetchPlan',
  initialState,
  reducers: {
    resetFetchPlanState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlanById.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchPlanById.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.plan = action.payload;
      })
      .addCase(fetchPlanById.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchPlanState } = fetchPlanByIdSlice.actions;
export default fetchPlanByIdSlice.reducer;
