import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { ICreateElement } from 'interfaces/requirements.interface';

export interface ICreateElementState {
  state: QueryState;
  element: Nullable<ICreateElement>;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const CreateElementEmptyState: ICreateElementState = {
  state: QueryState.UNDEFINED,
  element: null,
  error: null,
  accessToken: undefined
};
