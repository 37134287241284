import LZUTF8 from 'lzutf8';
import { IidTokenSession } from 'interfaces/session.interface';

function unChunk(charactersArray: string[]) {
  let result = '';
  charactersArray.forEach((characters: string) => {
    result += characters;
  });
  return LZUTF8.decompress(result, { inputEncoding: 'Base64' });
}

export function getAccountFromIdToken(idToken: IidTokenSession) {
  const chunks: string[] = [];
  for (let i = 1; i < 26; i++) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (idToken && idToken?.payload[`custom:chunk${i}`]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      chunks.push(idToken.payload[`custom:chunk${i}`]);
    }
  }

  return unChunk(chunks);
}
