import { createSlice } from '@reduxjs/toolkit';
import { QueryState } from 'common/enums';
import { FetchPalmettoUsersEmptyState, IFetchPalmettoUsersState } from './fetchPalmettoUsersState';
import { fetchPalmettoUsers } from './fetchPalmettoUsersThunk';

const initialState: IFetchPalmettoUsersState = FetchPalmettoUsersEmptyState;

export const fetchPalmettoUsersSlice = createSlice({
  name: 'fetchUsers',
  initialState,
  reducers: {
    resetFetchPalmettoUsersState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPalmettoUsers.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchPalmettoUsers.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.users = action.payload;
      })
      .addCase(fetchPalmettoUsers.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchPalmettoUsersState } = fetchPalmettoUsersSlice.actions;
export default fetchPalmettoUsersSlice.reducer;
