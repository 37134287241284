import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProjectDetails } from 'interfaces/project.interface';
import apiFetch from 'services/apiFetch';
import { fetchProjectByIdEndpoint } from 'services/projectsEndPoints';

export const fetchProjectById = createAsyncThunk('plan/fetchById', async (id: string) => {
  try {
    const endpoint = fetchProjectByIdEndpoint(id);
    const resp = await apiFetch<IProjectDetails>(endpoint).then((res) => res?.data || null);
    if (resp.projectTypeInfo) {
      resp.projectTypeInfo.forEach((p) => {
        p.zipCode = p.zipCode.toString();
      });
    }
    return resp;
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
