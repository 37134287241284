import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateJurisdictions } from './updateJurisdictionThunk';
import { QueryState } from 'common/enums';
import { IJurisdiction } from 'interfaces/jurisdiction.interface';
import { JurisdictionsEmptyState, IJurisdictionsState } from './updateJurisdictionState';

const initialState: IJurisdictionsState = JurisdictionsEmptyState;

export const updateJurisdictionsSlice = createSlice({
  name: 'updateJurisdictions',
  initialState,
  reducers: {
    updateJurisdictions: (state, action: PayloadAction<IJurisdiction[]>) => {
      state.jurisdictions = action.payload;
    },
    resetUpdateJurisdictionState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(updateJurisdictions.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(updateJurisdictions.fulfilled, (state, action) => {
        state.state = QueryState.UPDATED;
      })
      .addCase(updateJurisdictions.rejected, (state, action) => {
        state.state = QueryState.FAIL;
      });
  }
});

export const { resetUpdateJurisdictionState } = updateJurisdictionsSlice.actions;
export default updateJurisdictionsSlice.reducer;
