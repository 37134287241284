import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { ICreateRequirement } from 'interfaces/requirements.interface';

export interface ICreateRequirementState {
  state: QueryState;
  requirement: Nullable<ICreateRequirement>;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const CreateRequirementEmptyState: ICreateRequirementState = {
  state: QueryState.UNDEFINED,
  requirement: null,
  error: null,
  accessToken: undefined
};
