import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateElementEmptyState, ICreateElementState } from './createElementState';
import { createChecklistElement } from './createElementThunk';
import { QueryState } from 'common/enums';
import { ICreateElement } from 'interfaces/requirements.interface';

const initialState: ICreateElementState = CreateElementEmptyState;

export const createElementSlice = createSlice({
  name: 'createElement',
  initialState,
  reducers: {
    createElement: (state, action: PayloadAction<ICreateElement>) => {
      state.element = action.payload;
    },
    resetCreateElementState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createChecklistElement.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(createChecklistElement.fulfilled, (state, action) => {
        state.state = QueryState.CREATED;
      })
      .addCase(createChecklistElement.rejected, (state, action) => {
        state.state = QueryState.FAIL;
      });
  }
});

export const { resetCreateElementState } = createElementSlice.actions;
export default createElementSlice.reducer;
