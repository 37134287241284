import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateElement } from './updateElementThunk';
import { QueryState } from 'common/enums';
import { IUpdateElementState, UpdateElementEmptyState } from './updateElementState';

const initialState: IUpdateElementState = UpdateElementEmptyState;

export const updateElementSlice = createSlice({
  name: 'updateElement',
  initialState,
  reducers: {
    resetUpdateElementState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(updateElement.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(updateElement.fulfilled, (state, action) => {
        state.state = QueryState.UPDATED;
      })
      .addCase(updateElement.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetUpdateElementState } = updateElementSlice.actions;
export default updateElementSlice.reducer;
