import { createSlice } from '@reduxjs/toolkit';
import {
  ChecklistElementEmptyState,
  IChecklistElementState
} from './fetchDefaultChecklistElementsState';
import { fetchDefaultChecklistElement } from './fetchDefaultChecklistElementsThunk';
import { QueryState } from 'common/enums';

const initialState: IChecklistElementState = ChecklistElementEmptyState;

export const fetchDefaultChecklistElementSlice = createSlice({
  name: 'fetchChecklistElement',
  initialState,
  reducers: {
    resetFetchChecklistElementState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDefaultChecklistElement.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchDefaultChecklistElement.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.elements = action.payload;
      })
      .addCase(fetchDefaultChecklistElement.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchChecklistElementState } = fetchDefaultChecklistElementSlice.actions;
export default fetchDefaultChecklistElementSlice.reducer;
