import { createAsyncThunk } from '@reduxjs/toolkit';
import { IJurisdiction } from 'interfaces/jurisdiction.interface';
import apiFetch from 'services/apiFetch';
import { getJurisdictionsByPlanId } from 'services/jurisdictionsEndPoints';

export const fetchJurisdictionsByPlanId = createAsyncThunk(
  'jurisdictions/fetchByPlanId',
  async (id: string) => {
    try {
      const endpoint = getJurisdictionsByPlanId(id);
      const resp = await apiFetch<IJurisdiction[]>(endpoint).then((res) => res?.data || null);
      resp.map((jurisdiction) => {
        jurisdiction.jurisdictionId = jurisdiction.id;
      });
      return resp;
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
