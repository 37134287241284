import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { createNotificationContactEndpoint } from '../../../services/notificationContactEndPoints';
import { INotificationContact } from 'interfaces/notification.interface';

export const createNotificationContactThunk = createAsyncThunk(
  'createNotificationContact',
  async (data: INotificationContact) => {
    try {
      const endpoint = createNotificationContactEndpoint(data);
      return await apiFetch<Partial<INotificationContact>>(endpoint).then(
        (res) => res?.data || null
      );
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('createNotificationContact catch:', message);
      throw Error(message);
    }
  }
);
