import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { duplicateAndArchiveEndpoint } from 'services/plansEndPoints';

export const duplicateAndArchivePlanThunk = createAsyncThunk(
  'duplicatePlan',
  async (id: string) => {
    try {
      const endpoint = duplicateAndArchiveEndpoint(id);
      return await apiFetch(endpoint).then((res) => res?.data || null);
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('duplicateArchivePlan catch:', message);
      throw Error(message);
    }
  }
);
