import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { IChecklistResponseDetails } from 'interfaces/checklist.interface';

export interface IChecklistResponsesState {
  state: QueryState;
  responses: IChecklistResponseDetails[];
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const ChecklistResponsesEmptyState: IChecklistResponsesState = {
  state: QueryState.UNDEFINED,
  responses: [],
  error: null,
  accessToken: undefined
};
