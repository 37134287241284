const CountiesList = [
  { label: 'Abbeville', value: 'Abbeville' },
  { label: 'Aiken', value: 'Aiken' },
  { label: 'Allendale', value: 'Allendale' },
  { label: 'Anderson', value: 'Anderson' },
  { label: 'Bamberg', value: 'Bamberg' },
  { label: 'Barnwell', value: 'Barnwell' },
  { label: 'Beaufort', value: 'Beaufort' },
  { label: 'Berkeley', value: 'Berkeley' },
  { label: 'Calhoun', value: 'Calhoun' },
  { label: 'Charleston', value: 'Charleston' },
  { label: 'Cherokee', value: 'Cherokee' },
  { label: 'Chester', value: 'Chester' },
  { label: 'Chesterfield', value: 'Chesterfield' },
  { label: 'Clarendon', value: 'Clarendon' },
  { label: 'Colleton', value: 'Colleton' },
  { label: 'Darlington', value: 'Darlington' },
  { label: 'Dillon', value: 'Dillon' },
  { label: 'Dorchester', value: 'Dorchester' },
  { label: 'Edgefield', value: 'Edgefield' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Florence', value: 'Florence' },
  { label: 'Georgetown', value: 'Georgetown' },
  { label: 'Greenville', value: 'Greenville' },
  { label: 'Greenwood', value: 'Greenwood' },
  { label: 'Hampton', value: 'Hampton' },
  { label: 'Horry', value: 'Horry' },
  { label: 'Jasper', value: 'Jasper' },
  { label: 'Kershaw', value: 'Kershaw' },
  { label: 'Lancaster', value: 'Lancaster' },
  { label: 'Laurens', value: 'Laurens' },
  { label: 'Lee', value: 'Lee' },
  { label: 'Lexington', value: 'Lexington' },
  { label: 'Marion', value: 'Marion' },
  { label: 'Marlboro', value: 'Marlboro' },
  { label: 'McCormick', value: 'McCormick' },
  { label: 'Newberry', value: 'Newberry' },
  { label: 'Oconee', value: 'Oconee' },
  { label: 'Orangeburg', value: 'Orangeburg' },
  { label: 'Pickens', value: 'Pickens' },
  { label: 'Richland', value: 'Richland' },
  { label: 'Saluda', value: 'Saluda' },
  { label: 'Spartanburg', value: 'Spartanburg' },
  { label: 'Sumter', value: 'Sumter' },
  { label: 'Union', value: 'Union' },
  { label: 'Williamsburg', value: 'Williamsburg' },
  { label: 'York', value: 'York' }
];

export default { CountiesList };
