import React, { Suspense, lazy } from 'react';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route } from 'react-router-dom';

import { PreLoaderSnippet } from 'components/snippets';
import AuthPage from 'auth';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';

const PlansList = lazy(() => import('pages/PlansList'));
const PlanDetails = lazy(() => import('pages/PlanDetails'));
const ChecklistEdit = lazy(() => import('pages/ChecklistEdit'));
const ChecklistView = lazy(() => import('pages/ChecklistView'));
const AdoptionEdit = lazy(() => import('pages/AdoptionEdit'));
const PlanView = lazy(() => import('pages/PlanView'));
const AdoptionViewContainer = lazy(() => import('pages/AdoptionView'));
const TimelineContainer = lazy(() => import('pages/Timeline/Timeline.container'));
const HomeContainer = lazy(() => import('../pages/Home/Home.container'));
const DocumentsContainer = lazy(() => import('pages/Documents/Documents.container'));
const DocumentsMoveContainer = lazy(() => import('pages/DocumentsMove/DocumentsMove.container'));
const RequirementsContainer = lazy(() => import('pages/Requirements/Requirements.container'));
const RequirementDetailsContainer = lazy(
  () => import('pages/RequirementDetails/RequirementDetails.container')
);
const RequirementViewContainer = lazy(
  () => import('pages/RequirementView/RequirementView.container')
);
const ProjectDetails = lazy(() => import('pages/ProjectDetails'));
const ProjectView = lazy(() => import('pages/ProjectView'));
const ProjectsList = lazy(() => import('pages/ProjectsList'));
const ContactSupport = lazy(() => import('pages/ContactSupport'));
import MapArgcis from '../pages/MapArcgis/MapArcgis';

const JSXRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AuthPage />}>
      <Route
        path="mitigation"
        element={
          <Suspense
            fallback={
              <PreLoaderSnippet
                visible={true}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '500px'
                }}
              />
            }>
            {/*<ScriptComponent />*/}
            <Outlet />
          </Suspense>
        }>
        <Route index element={<HomeContainer />} />
        <Route path="plans">
          <Route index element={<PlansList />} />
          <Route path="new" element={<PlanDetails newEntry={true} />} />
          <Route path="edit/:id" element={<PlanDetails />} />
          <Route path=":id" element={<PlanView />} />
        </Route>
        <Route path="checklist">
          <Route path="edit/:id" element={<ChecklistEdit />} />
          <Route path=":id" element={<ChecklistView />} />
        </Route>
        <Route path="adoption">
          <Route path="edit/:id" element={<AdoptionEdit />} />
          <Route path=":id" element={<AdoptionViewContainer />} />
        </Route>
        <Route path="timeline">
          <Route path=":id" element={<TimelineContainer />} />
        </Route>
        <Route path="documents">
          <Route path=":id" element={<DocumentsContainer />} />
          <Route path=":id/move" element={<DocumentsMoveContainer />} />
        </Route>
        <Route path="projects">
          <Route index element={<ProjectsList />} />
          <Route path="new" element={<ProjectDetails newEntry={true} />} />
          <Route path="edit/:id" element={<ProjectDetails />} />
          <Route path=":id" element={<ProjectView />} />
        </Route>
        <Route path="requirements">
          <Route index element={<RequirementsContainer />} />
          <Route
            path="element/:elemId/requirement/new"
            element={<RequirementDetailsContainer newEntry={true} />}
          />
          <Route path="element/:elemId/requirement/:reqId" element={<RequirementViewContainer />} />
          <Route
            path="element/:elemId/requirement/:reqId/edit"
            element={<RequirementDetailsContainer />}
          />
        </Route>
        <Route path="map" element={<MapArgcis />} />
        <Route path="contact-support" element={<ContactSupport />} />
      </Route>
      <Route path="mitigation/login" element={<Login />} />
      <Route path="mitigation/logout" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

export default JSXRoutes;
