import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { MainLayout } from 'components/layouts';

const NotFound: React.FC = () => {
  return (
    <MainLayout>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="70vh">
        <Typography fontSize="120px" fontWeight={400} color="primary">
          404
        </Typography>
        <Typography variant="h4">Oops! That page can’t be found.</Typography>
        <Typography variant="body1" style={{ marginTop: '2rem' }}>
          <Link to="/mitigation">Back to main site</Link>
        </Typography>
      </Box>
    </MainLayout>
  );
};
export default NotFound;
