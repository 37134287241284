import React from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { InputAdornment } from '@mui/material';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';

const DateInputField: React.FC<{ className?: string } & TextFieldProps> = ({
  className, // eslint-disable-line
  ...props
}) => {
  return (
    <MuiTextField
      variant="outlined"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CalendarTodayIcon fontSize="small" />
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
};

export default DateInputField;
