import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { getPalmettoUsersEndpoint } from '../../../services/usersPalmettoEndPoints';
import { userFiltersSearch } from 'interfaces/userinfo.interface';

export const fetchPalmettoUsers = createAsyncThunk(
  'usersPalmetto/fetch',
  async (filters: userFiltersSearch) => {
    try {
      const endpoint = getPalmettoUsersEndpoint(filters);
      const resp = await apiFetch<any>(endpoint).then((res) => res?.data.dataset || null);
      return resp;
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
