import { createSlice } from '@reduxjs/toolkit';
import { createNotificationContactThunk } from './createNotificationContactThunk';
import { QueryState } from 'common/enums';
import {
  CreateNotificationContactEmptyState,
  ICreateNotificationContactState
} from './createNotificationContactState';

const initialState: ICreateNotificationContactState = CreateNotificationContactEmptyState;

export const createNotificationContactSlice = createSlice({
  name: 'createNotificationContact',
  initialState,
  reducers: {
    resetCreateNotificationContactState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createNotificationContactThunk.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(createNotificationContactThunk.fulfilled, (state) => {
        state.state = QueryState.CREATED;
      })
      .addCase(createNotificationContactThunk.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetCreateNotificationContactState } = createNotificationContactSlice.actions;
export default createNotificationContactSlice.reducer;
