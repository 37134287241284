import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { createInquiry } from 'services/checklistEndPoints';
import { ICreateInquiry } from 'interfaces/requirements.interface';

export const createChecklistInquiry = createAsyncThunk(
  'checklist/createInquiry',
  async (data: ICreateInquiry) => {
    try {
      const endpoint = createInquiry(data);
      return await apiFetch<Partial<ICreateInquiry>>(endpoint).then((res) => res?.data || null);
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('Create Inquiry catch:', message);
      throw Error(message);
    }
  }
);
