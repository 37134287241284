import { createSlice } from '@reduxjs/toolkit';
import { fetchProjectTypesThunk } from './fetchProjectTypesThunk';
import { QueryState } from 'common/enums';
import { FetchProjectTypesEmptyState, IProjectTypesState } from './fetchProjectTypesState';

const initialState: IProjectTypesState = FetchProjectTypesEmptyState;

export const fetchProjectTypes = createSlice({
  name: 'fetchProjectTypes',
  initialState,
  reducers: {
    resetFetchProjectTypes: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProjectTypesThunk.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchProjectTypesThunk.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.projectTypes = action.payload;
      })
      .addCase(fetchProjectTypesThunk.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchProjectTypes } = fetchProjectTypes.actions;
export default fetchProjectTypes.reducer;
