import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { IUpdateOrganization } from 'interfaces/plan.interface';
export interface IOrganizationsState {
  state: QueryState;
  organizations: Nullable<IUpdateOrganization>;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const OrganizationsEmptyState: IOrganizationsState = {
  state: QueryState.UNDEFINED,
  organizations: null,
  error: null,
  accessToken: undefined
};
