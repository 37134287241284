import { createSlice } from '@reduxjs/toolkit';
import {
  ChecklistResponsesEmptyState,
  IChecklistResponsesState
} from './fetchChecklistResponsesState';
import { fetchChecklistResponses } from './fetchChecklistResponsesThunk';
import { QueryState } from 'common/enums';

const initialState: IChecklistResponsesState = ChecklistResponsesEmptyState;

export const fetchChecklistResponsesSlice = createSlice({
  name: 'fetchChecklist',
  initialState,
  reducers: {
    resetFetchChecklistResponsesState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchChecklistResponses.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchChecklistResponses.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.responses = action.payload;
      })
      .addCase(fetchChecklistResponses.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchChecklistResponsesState } = fetchChecklistResponsesSlice.actions;
export default fetchChecklistResponsesSlice.reducer;
