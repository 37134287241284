import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultDrawerMenuState, IDrawerMenuState } from './drawerMenuState';

const initialState: IDrawerMenuState = DefaultDrawerMenuState;

export const drawerMenuSlice = createSlice({
  name: 'drawerMenu',
  initialState,
  reducers: {
    changeMenu: (state, action: PayloadAction<{ BtnAction: boolean }>) => {
      state.openDrawer = action.payload.BtnAction;
    },
    changeActivePath: (state, action: PayloadAction<{ activePath: string }>) => {
      state.activePath = action.payload.activePath;
    }
  }
});

export const { changeMenu, changeActivePath } = drawerMenuSlice.actions;

export default drawerMenuSlice.reducer;
