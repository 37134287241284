import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const verifyToken = createAsyncThunk('verifyTokenThunk', async (token: string) => {
  const headers = {
    Authorization: token
  };
  try {
    const tokenVerification = await axios.get(
      'https://portal.palmettoeoc.com/prod/public/api/cognito/account',
      { headers }
    );
    if (tokenVerification) return tokenVerification.data;
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
