import { createSlice } from '@reduxjs/toolkit';
import { fetchLocalFiles } from './fetchLocalFilesThunk';
import { QueryState } from 'common/enums';
import { ILocalFilesState, LocalFilesEmptyState } from './fetchLocalFilesState';

const initialState: ILocalFilesState = LocalFilesEmptyState;

export const fetchLocalFilesSlice = createSlice({
  name: 'fetchLocalFiles',
  initialState,
  reducers: {
    resetfetchLocalFilesState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLocalFiles.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchLocalFiles.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.dataset = action.payload.dataset;
        state.datasetByFolder = action.payload.datasetByFolder;
        state.parentID = action.payload.parent_id;
      })
      .addCase(fetchLocalFiles.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetfetchLocalFilesState } = fetchLocalFilesSlice.actions;
export default fetchLocalFilesSlice.reducer;
