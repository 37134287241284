import { createSlice } from '@reduxjs/toolkit';
import { QueryState } from 'common/enums';
import {
  CreateSupportMessageEmptyState,
  ICreateSupportMessageState
} from './createSupportMessageState';
import { createSupportMessage } from './createSupportMessageThunk';

const initialState: ICreateSupportMessageState = CreateSupportMessageEmptyState;

export const createSupportMessageSlice = createSlice({
  name: 'createSupportMessage',
  initialState,
  reducers: {
    resetCreateSupportMessageState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createSupportMessage.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(createSupportMessage.fulfilled, (state, action) => {
        state.state = QueryState.CREATED;
      })
      .addCase(createSupportMessage.rejected, (state, action) => {
        state.state = QueryState.FAIL;
      });
  }
});

export const { resetCreateSupportMessageState } = createSupportMessageSlice.actions;
export default createSupportMessageSlice.reducer;
