import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { IRequirement } from 'interfaces/requirements.interface';

export interface IRequirementState {
  state: QueryState;
  requirement: Nullable<IRequirement>;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const RequirementEmptyState: IRequirementState = {
  state: QueryState.UNDEFINED,
  requirement: null,
  error: null,
  accessToken: undefined
};
