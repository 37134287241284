import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../common/enums/AuthProtect';
import { IChecklistResponseToSave, IChecklistUpdateElement } from 'interfaces/checklist.interface';
import {
  ICreateElement,
  ICreateInquiry,
  ICreateRequirement,
  IUpdateRequirement
} from 'interfaces/requirements.interface';

export const getDefaultChecklistTemplate = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `checklist`
  };
};

export const getDefaultChecklistElements = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `checklist/elements`
  };
};

export const createElement = (data: ICreateElement): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: 'checklist/element',
    data
  };
};

export const createRequirement = (data: ICreateRequirement): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: 'checklist/requirement',
    data
  };
};

export const updateRequirementEndpoint = (data: IUpdateRequirement): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: 'checklist/requirement',
    data
  };
};

export const createInquiry = (data: ICreateInquiry): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: 'checklist/inquiry',
    data
  };
};

export const getChecklistResponses = (planId: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `checklist/responses/${planId}`
  };
};

export const updateChecklistResponsesEndpoint = (data: IChecklistResponseToSave): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: 'responses',
    data
  };
};

export const getRequirementById = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `checklist/requirement/${id}`
  };
};

export const getElementRequirementsCount = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `checklist/element/${id}/countRequirements`
  };
};

export const updateElementEndpoint = (data: IChecklistUpdateElement): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: 'checklist/element',
    data
  };
};

export const deleteElementEndpoint = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'DELETE',
    url: `checklist/element/${id}`
  };
};

export const deleteRequirementEndpoint = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'DELETE',
    url: `checklist/requirement/${id}`
  };
};
