import { createSlice } from '@reduxjs/toolkit';
import { updateFilesEntries } from './updateFilesThunk';
import { QueryState } from 'common/enums';
import { IUpdateFilesState, UpdateFilesEmptyState } from './updateFilesState';

const initialState: IUpdateFilesState = UpdateFilesEmptyState;

export const updateFilesSlice = createSlice({
  name: 'updateFiles',
  initialState,
  reducers: {
    resetUpdateFilesState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(updateFilesEntries.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(updateFilesEntries.fulfilled, (state, action) => {
        state.state = QueryState.UPDATED;
      })
      .addCase(updateFilesEntries.rejected, (state, action) => {
        state.state = QueryState.FAIL;
      });
  }
});

export const { resetUpdateFilesState } = updateFilesSlice.actions;
export default updateFilesSlice.reducer;
