import { IProjectDetails } from 'interfaces/project.interface';
import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../common/enums/AuthProtect';
import { setParams } from 'helpers/functions';
import { IManageColumns } from 'interfaces/project.interface';

export const createProjectEndpoint = (data: IProjectDetails): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: 'project',
    data
  };
};

export const updateProjectEndpoint = (data: IProjectDetails): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: 'project',
    data
  };
};

export const fetchProjectByIdEndpoint = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `project/${id}`
  };
};

export const fetchAllProjectsEndpoint = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: 'project'
  };
};

export const fetchCountProjectsByTypeEndpoint = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: 'project/typesCount'
  };
};

export const fetchProjectTypesEndpoint = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: 'project/types'
  };
};

export const fetchProjectManageColumnsEndpoint = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: 'manage/project/manage-columns'
  };
};

export const fetchProjectsAndTypesEndpoint = (filters: IManageColumns): IEndpoint => {
  const params = setParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: 'projectsAndTypes',
    params: params
  };
};
