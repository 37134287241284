import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { updateRequirementEndpoint } from 'services/checklistEndPoints';
import { IUpdateRequirement } from 'interfaces/requirements.interface';

export const updateRequirement = createAsyncThunk(
  'responses/updateRequirement',
  async (data: IUpdateRequirement) => {
    try {
      const endpoint = updateRequirementEndpoint(data);
      return await apiFetch<Partial<IUpdateRequirement>>(endpoint).then((res) => res?.data || null);
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('updateRequirement catch:', message);
      throw Error(message);
    }
  }
);
