import { createSlice } from '@reduxjs/toolkit';
import { QueryState } from 'common/enums';
import { deleteFile } from './deleteFileThunk';
import { DeleteFileEmptyState, IDeleteFileState } from './deleteFileState';

const initialState: IDeleteFileState = DeleteFileEmptyState;

export const deleteFileSlice = createSlice({
  name: 'deleteFile',
  initialState,
  reducers: {
    resetDeleteFileState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(deleteFile.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(deleteFile.fulfilled, (state) => {
        state.state = QueryState.DELETED;
      })
      .addCase(deleteFile.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetDeleteFileState } = deleteFileSlice.actions;
export default deleteFileSlice.reducer;
