import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../common/enums/AuthProtect';
import { IAddTimeline } from 'interfaces/timeline.interface';
import { ITimelineFormData } from 'pages/Timeline/hooks/useTimelineForm';

export const getTimelineByPlanId = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `timeline/${id}`
  };
};

export const createTimelineItem = (data: IAddTimeline): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: 'timeline',
    data
  };
};

export const updateTimelineItem = (data: ITimelineFormData): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: 'timeline',
    data
  };
};
