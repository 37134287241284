import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { archivePlanEndpoint } from 'services/plansEndPoints';

export const archivePlanThunk = createAsyncThunk('archivePlan', async (id: string) => {
  try {
    const endpoint = archivePlanEndpoint(id);
    return await apiFetch(endpoint).then((res) => res?.data || null);
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('archivePlan catch:', message);
    throw Error(message);
  }
});
