import { createSlice } from '@reduxjs/toolkit';
import { fetchCountProjectTypes } from './fetchCountProjectsByTypeThunk';
import { QueryState } from 'common/enums';
import {
  CountProjectTypesEmptyState,
  ICountProjectTypesState
} from './fetchCountProjectsByTypeState';

const initialState: ICountProjectTypesState = CountProjectTypesEmptyState;

export const fetchCountProjectTypesSlice = createSlice({
  name: 'fetchCountProjectTypes',
  initialState,
  reducers: {
    resetFetchCountProjectTypesState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCountProjectTypes.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchCountProjectTypes.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.projectTypes = action.payload;
      })
      .addCase(fetchCountProjectTypes.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchCountProjectTypesState } = fetchCountProjectTypesSlice.actions;
export default fetchCountProjectTypesSlice.reducer;
