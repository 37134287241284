import { ISelectProp } from 'interfaces/SelectProp';
import React from 'react';
import ReactSelect, {
  CSSObjectWithLabel,
  ActionMeta,
  SingleValue,
  ControlProps,
  GroupBase
} from 'react-select';

interface ISingleSelect {
  white?: boolean;
  error?: boolean;
  onChange:
    | ((newValue: SingleValue<ISelectProp>, actionMeta: ActionMeta<ISelectProp>) => void)
    | undefined;
  options: ISelectProp[];
  value: ISelectProp | undefined;
  selectId?: string;
  name?: string;
  disabled?: boolean;
}

const SingleSelectView: React.FC<ISingleSelect> = ({
  white = false,
  error,
  options,
  onChange,
  value,
  selectId,
  disabled = false
}) => {
  type StylesType = Record<
    string,
    (
      base: (props?: Record<string, CSSObjectWithLabel>) => CSSObjectWithLabel,
      state: ControlProps<ISelectProp, boolean, GroupBase<ISelectProp>>
    ) => CSSObjectWithLabel
  >;

  const selectStyles: StylesType = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      color: 'black',
      height: '40px',
      borderColor: state.isDisabled ? 'rgba(0, 0, 0, 0.26)' : error ? 'red' : '#fafafa',
      background: '#fafafa',
      '&:hover': {
        cursor: 'text',
        borderColor: state.isFocused ? '#2961fd' : '#000'
      }
    }),
    menuPortal: (base) => ({ ...base, zIndex: 999999 })
  };

  return (
    // <div style={{ minHeight: '56px', minWidth: '250px' }}>
    <ReactSelect
      styles={selectStyles}
      options={options || []}
      onChange={onChange}
      value={value}
      inputId={selectId}
      isDisabled={disabled}
      menuPortalTarget={document.body}
    />
    // </div>
  );
};

export default SingleSelectView;
