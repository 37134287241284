import { createSlice } from '@reduxjs/toolkit';
import { RequirementEmptyState, IRequirementState } from './fetchRequirementByIdState';
import { fetchRequirementById } from './fetchRequirementByIdThunk';
import { QueryState } from 'common/enums';

const initialState: IRequirementState = RequirementEmptyState;

export const fetchRequirementByIdSlice = createSlice({
  name: 'fetchRequirement',
  initialState,
  reducers: {
    resetFetchRequirementState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRequirementById.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchRequirementById.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.requirement = action.payload;
      })
      .addCase(fetchRequirementById.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchRequirementState } = fetchRequirementByIdSlice.actions;
export default fetchRequirementByIdSlice.reducer;
