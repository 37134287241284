import { createAsyncThunk } from '@reduxjs/toolkit';
import { IChecklistDetails } from 'interfaces/checklist.interface';
import apiFetch from 'services/apiFetch';
import { getDefaultChecklistTemplate } from 'services/checklistEndPoints';

export const fetchDefaultChecklistTemplate = createAsyncThunk(
  'fetchChecklistTemplate',
  async () => {
    try {
      const endpoint = getDefaultChecklistTemplate();
      const resp = await apiFetch<IChecklistDetails>(endpoint).then((res) => res?.data || null);
      return resp;
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
