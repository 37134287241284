import { createSlice } from '@reduxjs/toolkit';
import { fetchNotificationContactsThunk } from './fetchNotificationContactsThunk';
import { QueryState } from 'common/enums';
import {
  FetchNotificationContactsEmptyState,
  IFetchNotificationContactsState
} from './fetchNotificationContactsState';

const initialState: IFetchNotificationContactsState = FetchNotificationContactsEmptyState;

export const fetchNotificationContactsSlice = createSlice({
  name: 'fetchNotificationContacts',
  initialState,
  reducers: {
    resetFetchNotificationContactsState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNotificationContactsThunk.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchNotificationContactsThunk.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.contacts = action.payload;
      })
      .addCase(fetchNotificationContactsThunk.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchNotificationContactsState } = fetchNotificationContactsSlice.actions;
export default fetchNotificationContactsSlice.reducer;
