// COGNITO
// import { Nullable } from 'common/utils/Nullable';
//
// export interface ITokenState {
//   authorization: string | null | undefined;
//   jwToken: Nullable<string>;
//   ttl: number | undefined;
//   createdAt: number | undefined;
//   user: string | undefined;
//   email: string | undefined;
// }
//
// export const UserAuthEmptyState: ITokenState = {
//   authorization: null,
//   jwToken: null,
//   ttl: 0,
//   createdAt: 0,
//   user: undefined,
//   email: undefined
// };

//PALMETTO ACCOUNT
import { Nullable } from 'common/utils/Nullable';

export type UserInfoAuth = {
  id?: string;
  email: string;
  name: string;
  status?: string;
  account?: string;
  workNumber?: Nullable<string>;
  mobileNumber?: Nullable<string>;
  accessType?: string;
};

export interface ITokenState {
  jwToken: Nullable<string>;
  authorization: string | null | undefined;
  ttl: number | undefined;
  createdAt: number | undefined;
  user: Nullable<UserInfoAuth> | undefined;
}

export const UserAuthEmptyState: ITokenState = {
  jwToken: null,
  authorization: null,
  ttl: 0,
  createdAt: 0,
  user: null
};
