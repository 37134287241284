//COGNITO
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectCognitoUser, selectUserAccount } from 'store/selectors';
import { AccessTypes } from 'common/enums';
import axios from 'axios';
// import { updateToken } from 'store/entities/userAuth/tokenSlice';
import { updateTokenState } from 'store/entities/userAuth/tokenSlice';
import { ITokenState } from 'store/entities/userAuth/TokenState';
import { updateCurrentUser } from '../store/entities/currentUser/currentUserSlice';
import { SetAccessType } from 'common/utils';
import { verifyToken } from 'store/entities';
import { selectTokenVerification } from 'store/selectors';
import { cloneDeep } from 'lodash';
const AuthPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const cognitoUser = useAppSelector(selectCognitoUser);
  const userAccount = useAppSelector(selectUserAccount);
  const [searchParams] = useSearchParams();
  const [id_token, setId_token] = useState('');
  const idToken = searchParams.get('id_token');
  const urlPath = location.pathname;
  let accessType = '';
  const verifyTokenStore = useAppSelector(selectTokenVerification);
  const resTokenVerification = cloneDeep(verifyTokenStore.tokenVerification);

  interface StatusData {
    [key: string]: { [key: string]: string | { [key: string]: string }[] }[];
  }

  const getPermissionsForSite = (
    requestedSite: string,
    requestedGroupId: number,
    data: StatusData[]
  ): { sitePermissions: string; status: string | { [p: string]: string }[] } => {
    const sitePermissions: string[] = [];
    let siteStatus: string | { [p: string]: string }[] = 'DNY';
    if (!data) {
      return { sitePermissions: sitePermissions[0], status: siteStatus };
    }
    /* Passed in JSON object looks like:
[
  {"PEOC":[{"S":"ACT"}]},
  {"LEMPG":[{"S":"DNY"}]},
  {"MITG":[{"S":"ACT"},{"1":[{"S":"ACT"}]}]},
  {"SERCLEPC":[{"S":"DNY"}]},
  {"PTRACK":[{"S":"DNY"}]},
  {"PTRAIN":[{"S":"DNY"}]},
  {"SUPPTL":[{"S":"DNY"}]},
  {"EGRESS":[{"S":"DNY"}]},
  {"DMA":[{"S":"DNY"}]},
  {"CDOWN":[{"S":"DNY"}]},
  {"REP":[{"S":"DNY"}]},
  {"VEOC":[{"S":"DNY"}]}
]
    */
    for (let i = 0; i < data.length; i++) {
      const attributes = data[i]; // {"MITG":[{"S":"ACT"},{"1":[{"S":"ACT"}]}]}
      const site = attributes && Object.keys(attributes) && Object.keys(attributes)[0];
      if (requestedSite === site) {
        for (let j = 0; j < Object.values(attributes)[0].length; j++) {
          // [{"S":"ACT"},{"1":[{"S":"ACT"}]}]
          const attribute = Object.values(attributes)[0][j];
          const key = Object.keys(attribute)[0];
          // key is either "S" or a group id.
          if (!isNaN(Number(key))) {
            // Group ID
            const groupId = parseInt(key);
            if (requestedGroupId === groupId) {
              for (let k = 0; k < Object.values(attribute)[0].length; k++) {
                // [{"S":"ACT"}]}]
                const values = Object.values(attribute)[0][k];
                if (values && Object.keys(values) && Object.keys(values)[0]) {
                  if (Object.keys(values)[0] === 'S') {
                    siteStatus = Object.values(values)[0];
                  } else if (Object.keys(values)[0] === 'P') {
                    sitePermissions.push(Object.values(values)[0]);
                    break;
                  }
                }
              }
              break;
            } // groupId
          } else {
            if (typeof Object.values(attribute)[0] === 'string') {
              siteStatus = Object.values(attribute)[0];
            }
          }
        }
        break;
      } // site
    }
    return { sitePermissions: sitePermissions[0], status: siteStatus };
  };

  useEffect(() => {
    if (idToken) {
      setId_token(idToken);
      dispatch(verifyToken(idToken));
    }
  }, [idToken]);

  useEffect(() => {
    localStorage.clear();
    if (resTokenVerification?.id) {
      const groupId = resTokenVerification.pvActiveGroupID;
      const permissions = getPermissionsForSite('MITG', groupId, resTokenVerification.attributes);
      const status = permissions.status;
      // status is now either ACT or DNY.
      if (status === 'DNY') throw new Error('User is not authorized to access this site.');
      const data = cloneDeep(resTokenVerification);
      accessType = SetAccessType(permissions.sitePermissions[0]);
      data.accessType = accessType;
      const payload: ITokenState = {
        authorization: data.accessToken,
        jwToken: id_token,
        // email: data.email,
        createdAt: 0,
        ttl: 0,
        user: {
          name: data.pvPersonGivenName + (data.pvPersonSurName ? ' ' + data.pvPersonSurName : ''),
          email: data.email,
          status: data.status,
          workNumber: data.pvOfficePhone || '',
          mobileNumber: data.pvMobilePhone || '',
          accessType: accessType
        }
      };
      dispatch(updateTokenState(payload));
      dispatch(updateCurrentUser(data));
      if (urlPath === '/requirements' && accessType !== AccessTypes.FULL_ACCESS) {
        return navigate(`${process.env.REACT_APP_APP_URL_PATH}/home`);
      }
      return navigate(`${process.env.REACT_APP_APP_URL_PATH}`);
      // return navigate(urlPath);
    } else if ((cognitoUser || userAccount) && userAccount?.id) {
      if (
        urlPath === '/mitigation/requirements' &&
        userAccount.accessType !== AccessTypes.FULL_ACCESS
      ) {
        return navigate(`${process.env.REACT_APP_APP_URL_PATH}`);
      }
      if (urlPath === '/') return navigate(`${process.env.REACT_APP_APP_URL_PATH}`);
      if (urlPath === '/login') return navigate(`${process.env.REACT_APP_APP_URL_PATH}`);
      if (urlPath === `${process.env.REACT_APP_APP_URL_PATH}/login`)
        return navigate(`${process.env.REACT_APP_APP_URL_PATH}`);
      if (urlPath === `${process.env.REACT_APP_APP_URL_PATH}/home`)
        return navigate(`${process.env.REACT_APP_APP_URL_PATH}`);
      if (urlPath === '/home') return navigate(`${process.env.REACT_APP_APP_URL_PATH}`);
      return navigate(urlPath);
    }
    navigate(`${process.env.REACT_APP_APP_URL_PATH}/login`);
  }, [verifyTokenStore]);

  return <Outlet />;
};

export default AuthPage;

//PALMETTO ACCOUNT
// import { useEffect } from 'react';
// import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// import { useAppSelector } from 'store/hooks';
// import { selectToken } from 'store/selectors';
//
// const AuthPage = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const token = useAppSelector(selectToken);
//
//   useEffect(() => {
//     const urlPath = location.pathname;
//     if (token && token.authorization !== null) {
//       if (urlPath === '/') return navigate(`${process.env.REACT_APP_APP_URL_PATH}`);
//       if (urlPath === '/login') return navigate(`${process.env.REACT_APP_APP_URL_PATH}`);
//       if (urlPath === `${process.env.REACT_APP_APP_URL_PATH}/login`)
//         return navigate(`${process.env.REACT_APP_APP_URL_PATH}`);
//       if (urlPath === `${process.env.REACT_APP_APP_URL_PATH}/home`)
//         return navigate(`${process.env.REACT_APP_APP_URL_PATH}`);
//       if (urlPath === '/home') return navigate(`${process.env.REACT_APP_APP_URL_PATH}`);
//       return navigate(urlPath);
//     }
//     navigate(`${process.env.REACT_APP_APP_URL_PATH}/login`);
//   }, [token]);
//
//   return <Outlet />;
// };
//
// export default AuthPage;
