import { createSlice } from '@reduxjs/toolkit';
import { fetchProjectsAndTypesThunk } from './fetchProjectsAndTypesThunk';
import { QueryState } from 'common/enums';
import {
  FetchProjectsAndTypesEmptyState,
  IFetchProjectsAndTypesState
} from './fetchProjectsAndTypesState';

const initialState: IFetchProjectsAndTypesState = FetchProjectsAndTypesEmptyState;

export const fetchProjectsAndTypes = createSlice({
  name: 'fetchProjectsAndTypes',
  initialState,
  reducers: {
    resetFetchProjectsAndTypes: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProjectsAndTypesThunk.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchProjectsAndTypesThunk.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.projectsAndTypes = action.payload;
      })
      .addCase(fetchProjectsAndTypesThunk.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchProjectsAndTypes } = fetchProjectsAndTypes.actions;
export default fetchProjectsAndTypes.reducer;
