import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderColor: '#2961FD',
      borderRadius: '8px',
      backgroundColor: '#F0F4FF',
      minHeight: '150px',
      color: '#2961FD'
    },
    text: {
      fontSize: '1.5rem'
    },
    previewChip: {
      minWidth: 160,
      maxWidth: 210
    }
  })
);

export default useStyles;
