import { QueryState } from '../../../common/enums';
import { Nullable } from '../../../common/utils';
import { IProjectDetails } from 'interfaces/project.interface';

export interface IFetchProjectsAndTypesState {
  state: QueryState;
  projectsAndTypes: IProjectDetails[];
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const FetchProjectsAndTypesEmptyState: IFetchProjectsAndTypesState = {
  state: QueryState.UNDEFINED,
  projectsAndTypes: [],
  error: null,
  accessToken: undefined
};
