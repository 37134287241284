import { QueryState } from '../../../common/enums';
import { Nullable } from '../../../common/utils';
export interface IUpdateFilesState {
  state: QueryState;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const UpdateFilesEmptyState: IUpdateFilesState = {
  state: QueryState.UNDEFINED,
  error: null,
  accessToken: undefined
};
