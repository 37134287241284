import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPlanDetails } from 'interfaces/plan.interface';
import apiFetch from 'services/apiFetch';
import { updatePlanEndpoint } from 'services/plansEndPoints';

export const updatePlan = createAsyncThunk('plans/updatePlan', async (data: IPlanDetails) => {
  try {
    const endpoint = updatePlanEndpoint(data);
    return await apiFetch<Partial<IPlanDetails>>(endpoint).then((res) => res?.data || null);
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('updatePlan catch:', message);
    throw Error(message);
  }
});
