// noinspection ExceptionCaughtLocallyJS

import { createAsyncThunk } from '@reduxjs/toolkit';
import * as AWS from 'aws-sdk';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { getAccountFromIdToken } from 'common/utils';
import { IUserMitigation } from 'interfaces/userinfo.interface';
import { RootState } from 'store';
import { IidTokenSession } from 'interfaces/session.interface';

AWS.config.region = 'us-east-1'; //process.env.REACT_APP_AWS_REGION;
const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID;
const USER_POOL_CLIENT_ID = process.env.REACT_APP_USER_POOL_CLIENT_ID;
let USER_POOL: CognitoUserPool;
let COGNITO_USER: CognitoUser | null;
if (USER_POOL_ID && USER_POOL_CLIENT_ID) {
  USER_POOL = new CognitoUserPool({
    UserPoolId: USER_POOL_ID,
    ClientId: USER_POOL_CLIENT_ID
  });
}
// function checkActiveSession(payload: {
//   username: string;
//   password: string;
//   activeId?: string;
// }): Promise<CognitoUser | null> {
//   return new Promise((res, rej) => {
//     try {
//       let currentUser = USER_POOL && USER_POOL.getCurrentUser();
//       if (currentUser !== null) {
//         // eslint-disable-next-line @typescript-eslint/no-unused-vars
//         currentUser.getSession((error: Error | null, session: CognitoUserSession | null) => {
//           if (error) {
//             rej(error);
//           } else {
//             // res(session);
//             res(currentUser);
//           }
//         });
//       } else {
//         currentUser = new CognitoUser({
//           Username: payload.username,
//           Pool: USER_POOL
//         });
//         // NEED THIS TO TRIGGER USER MIGRATION
//         currentUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
//         currentUser.authenticateUser(
//           new AuthenticationDetails({
//             Username: payload.username,
//             Password: payload.password
//             // ClientMetadata: {
//             //   ActiveGroupID: payload.activeId ? payload.activeId.toString() : '0',
//             // },
//           }),
//           {
//             // eslint-disable-next-line @typescript-eslint/no-unused-vars
//             onSuccess(session: any) {
//               return res(currentUser);
//             },
//             onFailure(error: any) {
//               //	"code": "NotAuthorizedException",
//               //	"name": "NotAuthorizedException",
//               //	"message": "Incorrect username or password."
//               //	"code": "UserNotFoundException",
//               //  "name": "UserNotFoundException",
//               let message = 'Un-authorize User Account';
//               const listPropertyNames = Object.keys(error);
//               // eslint-disable-next-line no-restricted-syntax
//               for (const key in listPropertyNames) {
//                 if (key === 'NotAuthorizedException') {
//                   message = 'Incorrect username or password.';
//                 }
//               }
//               return rej(Error(message));
//             }
//           }
//         );
//       }
//     } catch (error) {
//       console.log(error);
//       rej(error);
//     }
//   });
// }

function SetNewCognitoUser(payload: {
  username: string;
  password: string;
}): Promise<CognitoUser | null> {
  return new Promise((res, rej) => {
    try {
      const currentUser = new CognitoUser({
        Username: payload.username,
        Pool: USER_POOL
      });
      // *:NEED THIS TO TRIGGER USER MIGRATION
      currentUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
      currentUser.authenticateUser(
        new AuthenticationDetails({
          Username: payload.username,
          Password: payload.password
          // ClientMetadata: {
          //   ActiveGroupID: payload.activeId ? payload.activeId.toString() : '0',
          // },
        }),
        {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onSuccess(_session) {
            COGNITO_USER = currentUser;
            return res(currentUser);
          },
          onFailure(error) {
            //	? "code": "NotAuthorizedException"
            //	? "name": "NotAuthorizedException"
            //	? "message": "Incorrect username or password."
            //	? "code": "UserNotFoundException"
            //  ? "name": "UserNotFoundException"
            let message = 'Un-authorize User Account';
            const listPropertyNames = Object.keys(error);
            for (const key in listPropertyNames) {
              if (key === 'NotAuthorizedException') {
                message = 'Incorrect username or password.';
              }
            }
            return rej(message);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  });
}

function checkActiveSession(payload: {
  username: string;
  password: string;
  activeId?: string;
}): Promise<CognitoUser | null> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (res, rej) => {
    try {
      let currentUser = USER_POOL && USER_POOL.getCurrentUser();
      if (currentUser !== null) {
        currentUser = await SetNewCognitoUser(payload);
        return res(currentUser);
      } else {
        currentUser = await SetNewCognitoUser(payload);
        return res(currentUser);
      }
    } catch (error) {
      console.log(error);
      rej(error);
    }
  });
}

function changeGroupCognito(payload: {
  username: string;
  password: string;
  activeId: string;
}): Promise<CognitoUser | null> {
  return new Promise((res, rej) => {
    try {
      const currentUser = new CognitoUser({
        Username: payload.username,
        Pool: USER_POOL
      });
      currentUser.setAuthenticationFlowType('USER_SRP_AUTH');
      currentUser.authenticateUser(
        new AuthenticationDetails({
          Username: payload.username,
          Password: payload.password,
          ClientMetadata: {
            ActiveGroupID: payload.activeId
          }
        }),
        {
          onSuccess(session) {
            return res(currentUser);
          },
          onFailure(error) {
            return rej(error);
          }
        }
      );
    } catch (error) {
      console.log(error);
      rej(error);
    }
  });
}

function changePasswordCognito(payload: {
  oldPassword: string;
  newPassword: string;
}): Promise<string | undefined> {
  return new Promise((res, rej) => {
    try {
      const currentCognitoUser = USER_POOL && USER_POOL.getCurrentUser();
      if (currentCognitoUser !== null) {
        currentCognitoUser.changePassword(
          payload.oldPassword,
          payload.newPassword,
          function (err, result) {
            if (err) {
              return rej(err);
            }
            return res(result);
          }
        );
      } else {
        throw Error('No Active Cognito User');
      }
    } catch (error) {
      console.log(error);
      return rej(error);
    }
  });
}

export const refreshCognitoLogin = createAsyncThunk(
  'refresh-cognito',
  async (payload: { username: string; password: string; activeId?: string }) => {
    try {
      const userCognitoAccount: CognitoUser | null = await checkActiveSession(payload).then(
        (session) => session
      );
      if (userCognitoAccount) {
        let idToken;
        if (userCognitoAccount?.getSignInUserSession()) {
          idToken = userCognitoAccount?.getSignInUserSession()?.getIdToken();
        }
        if (!idToken) throw Error('No Id Token');
        const jwtToken = idToken.getJwtToken();
        const tokenSession: Partial<IidTokenSession> = idToken;
        return {
          jwtToken,
          cognitoUser: JSON.stringify(tokenSession)
        };
      }
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('refresh-cognito catch:', error);
      throw Error(message);
    }
  }
);

export const cognitoLogin = createAsyncThunk(
  'cognito-login',
  async (payload: { username: string; password: string; activeId?: string }) => {
    try {
      const userCognitoAccount: CognitoUser | null = await checkActiveSession(payload)
        .then((session) => session)
        .catch((e) => {
          console.log('checkActiveSession error >>>', e);
          throw e;
        });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore

      if (userCognitoAccount) {
        let idToken;
        if (userCognitoAccount?.getSignInUserSession()) {
          idToken = userCognitoAccount?.getSignInUserSession()?.getIdToken();
        }
        if (!idToken) throw Error('No Id Token');
        const tokenSession: Partial<IidTokenSession> = idToken;
        const jwtToken = idToken.getJwtToken();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let accountInfo: Partial<IUserMitigation> = getAccountFromIdToken(idToken);
        accountInfo = JSON.parse(accountInfo as string);
        return {
          jwtToken,
          accountInfo,
          cognitoUser: JSON.stringify(tokenSession),
          activeGroupId: accountInfo.pvActiveGroupID
        };
      }

      throw Error('Cognito Login Error');
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('cognitoLogin catch:', error);
      throw Error(message);
    }
  }
);

export const cognitoLogout = createAsyncThunk('cognito-logout', async () => {
  try {
    const currentCognitoAccount = COGNITO_USER && COGNITO_USER.getSignInUserSession(); //USER_POOL && USER_POOL.getCurrentUser();
    if (currentCognitoAccount !== null) {
      const logOutResponse = await new Promise((res, rej) => {
        try {
          COGNITO_USER &&
            COGNITO_USER.signOut(() => {
              return res('Success');
            });
        } catch (error) {
          rej(error);
        }
      });
      if (logOutResponse instanceof Error) throw Error('Error Cognito Logout');
      return logOutResponse;
    } else {
      throw Error('Missing Cognito Current User');
    }
  } catch (error) {
    let message;
    if (error instanceof Error) message = error.message;
    else message = String(error);
    console.log('cognitoLogout catch:', error);
    throw Error(message);
  }
});

export const cognitoChangePassword = createAsyncThunk(
  'cognito-change-password',
  async (newPassword: string, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const oldPassword = store.cognito.password;

      if (oldPassword) {
        const result = await changePasswordCognito({ oldPassword, newPassword });
        return {
          result,
          newPassword
        };
      } else {
        throw Error('Missing required parameters!');
      }
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('cognito-change-password catch:', error);
      throw Error(message);
    }
  }
);
