import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from 'common/enums/AuthProtect';
export const getDefaultHomeText = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `home`
  };
};

export const updateHomeTextEndpoint = (id: string, data: { homepageText: string }): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: `home/${id}`,
    data
  };
};
