import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { IProjectDetails } from 'interfaces/project.interface';

export interface IFetchProjectState {
  state: QueryState;
  project: Nullable<IProjectDetails> | undefined;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const FetchProjectEmptyState: IFetchProjectState = {
  state: QueryState.UNDEFINED,
  project: null,
  error: null,
  accessToken: undefined
};
