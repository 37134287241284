import { createSlice } from '@reduxjs/toolkit';
import { fetchAllPlansThunk } from './fetchAllPlansThunk';
import { QueryState } from 'common/enums';
import { FetchPlansEmptyState, IFetchPlansState } from './fetchAllPlansState';

const initialState: IFetchPlansState = FetchPlansEmptyState;

export const fetchAllPlansSlice = createSlice({
  name: 'fetchAllPlans',
  initialState,
  reducers: {
    resetFetchAllPlansState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllPlansThunk.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchAllPlansThunk.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.plans = action.payload;
      })
      .addCase(fetchAllPlansThunk.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchAllPlansState } = fetchAllPlansSlice.actions;
export default fetchAllPlansSlice.reducer;
