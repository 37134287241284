import { createSlice } from '@reduxjs/toolkit';
import { deleteElement } from './deleteElementThunk';
import { QueryState } from 'common/enums';
import { DeleteElementEmptyState, IDeleteElementState } from './deleteElementState';

const initialState: IDeleteElementState = DeleteElementEmptyState;

export const deleteElementSlice = createSlice({
  name: 'deleteElement',
  initialState,
  reducers: {
    resetDeleteElementState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(deleteElement.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(deleteElement.fulfilled, (state, action) => {
        state.state = QueryState.DELETED;
      })
      .addCase(deleteElement.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetDeleteElementState } = deleteElementSlice.actions;
export default deleteElementSlice.reducer;
