import { createSlice } from '@reduxjs/toolkit';
import { UpdateProjectEmptyState, IUpdateProjectState } from './updateProjectState';
import { updateProject } from './updateProjectThunk';
import { QueryState } from 'common/enums';

const initialState: IUpdateProjectState = UpdateProjectEmptyState;

export const updateProjectSlice = createSlice({
  name: 'updatePlan',
  initialState,
  reducers: {
    resetUpdateProjectState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(updateProject.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.state = QueryState.UPDATED;
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.state = QueryState.FAIL;
      });
  }
});

export const { resetUpdateProjectState } = updateProjectSlice.actions;
export default updateProjectSlice.reducer;
