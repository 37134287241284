import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createFileLocally } from './createFileThunk';
import { QueryState } from 'common/enums';
import { IFileInfo } from 'interfaces/File';
import { CreateFileEmptyState, ICreateFileState } from './createFileState';

const initialState: ICreateFileState = CreateFileEmptyState;

export const createFileSlice = createSlice({
  name: 'createFile',
  initialState,
  reducers: {
    createFile: (state, action: PayloadAction<IFileInfo>) => {
      state.file = action.payload;
    },
    resetCreateFileState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createFileLocally.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(createFileLocally.fulfilled, (state, action) => {
        state.state = QueryState.CREATED;
      })
      .addCase(createFileLocally.rejected, (state, action) => {
        state.state = QueryState.FAIL;
      });
  }
});

export const { resetCreateFileState } = createFileSlice.actions;
export default createFileSlice.reducer;
