import { createSlice } from '@reduxjs/toolkit';
import { PlanAdoptionRateEmptyState, IPlanAdoptionRateState } from './updatePlanAdoptionRateState';
import { updatePlanAdoptionRate } from './updatePlanAdoptionRateThunk';
import { QueryState } from 'common/enums';

const initialState: IPlanAdoptionRateState = PlanAdoptionRateEmptyState;

export const updatePlanAdoptionRateSlice = createSlice({
  name: 'updatePlan',
  initialState,
  reducers: {
    resetUpdatePlanAdoptionRateState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(updatePlanAdoptionRate.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(updatePlanAdoptionRate.fulfilled, (state, action) => {
        state.state = QueryState.UPDATED;
      })
      .addCase(updatePlanAdoptionRate.rejected, (state, action) => {
        state.state = QueryState.FAIL;
      });
  }
});

export const { resetUpdatePlanAdoptionRateState } = updatePlanAdoptionRateSlice.actions;
export default updatePlanAdoptionRateSlice.reducer;
