import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addTimeline } from './addTimelineItemThunk';
import { QueryState } from 'common/enums';
import { AddTimelineEmptyState, IAddTimelineState } from './addTimelineItemState';

const initialState: IAddTimelineState = AddTimelineEmptyState;

export const addTimelineSlice = createSlice({
  name: 'addTimeline',
  initialState,
  reducers: {
    resetAddTimelineState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(addTimeline.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(addTimeline.fulfilled, (state) => {
        state.state = QueryState.CREATED;
      })
      .addCase(addTimeline.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});
export const { resetAddTimelineState } = addTimelineSlice.actions;
export default addTimelineSlice.reducer;
