import { ISelectProp } from 'interfaces/SelectProp';

const PlanNotificationTriggers: ISelectProp[] = [
  { label: 'Plan - 1 year', value: '31536000' },
  { label: 'Plan - 6 months', value: '15768000' },
  { label: 'Plan - 3 months', value: '7884000' },
  { label: 'Plan - 1 month', value: '2628000' }
];

export default PlanNotificationTriggers;
