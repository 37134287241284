import { Nullable } from 'common/utils/Nullable';
import { QueryState } from 'common/enums';

export interface IUpdateTimelineState {
  state: QueryState;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const UpdateTimelineEmptyState: IUpdateTimelineState = {
  state: QueryState.UNDEFINED,
  error: null,
  accessToken: undefined
};
