import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from 'common/enums/AuthProtect';
import { IPlanDetails, IUpdateOrganization } from 'interfaces/plan.interface';

export interface ApiPlanFilters {
  keyword?: string;
  approvedByFema?: boolean | null;
  approvedByState?: boolean | null;
  // planExpired?: boolean | null;
  submittedToFema?: boolean | null;
  submittedToState?: boolean | null;
  limit?: number;
  offset?: number;
}

export const createPlanEndpoint = (data: IPlanDetails): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: 'plan',
    data
  };
};

export const updatePlanEndpoint = (data: IPlanDetails): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: 'plan',
    data
  };
};

export const updatePlanAdoptionRateEndpoint = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: `plan/${id}/adoptionRate`
  };
};

export const fetchPlanByIdEndpoint = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `plan/${id}`
  };
};

export const updateOrganizationEndpoint = (data: IUpdateOrganization): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: 'plan/organization',
    data
  };
};

export const fetchAllPlansEndpoint = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: 'plan'
  };
};

export const duplicateAndArchiveEndpoint = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: `plan/${id}/duplicateAndArchive`
  };
};

export const fetchPlanManageColumnsEndpoint = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: 'manage/plan/manage-columns'
  };
};

export const deletePlanEndpoint = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'DELETE',
    url: `plan/${id}`
  };
};

export const archivePlanEndpoint = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: `plan/${id}/archive`
  };
};
