import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { fetchProjectManageColumnsEndpoint } from 'services/projectsEndPoints';
import { fetchPlanManageColumnsEndpoint } from 'services/plansEndPoints';
import { IManageColumns } from 'interfaces/project.interface';

export const fetchManageColumnsThunk = createAsyncThunk(
  'fetchManageColumns',
  async (type: string) => {
    try {
      const endpoint =
        type === 'project' ? fetchProjectManageColumnsEndpoint() : fetchPlanManageColumnsEndpoint();
      const resp = await apiFetch<IManageColumns>(endpoint).then((res) => res?.data || null);
      return resp;
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
