import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { IProjectDetails } from 'interfaces/project.interface';

export interface IFetchProjectsState {
  state: QueryState;
  projects: IProjectDetails[];
  count: number;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const FetchProjectsEmptyState: IFetchProjectsState = {
  state: QueryState.UNDEFINED,
  projects: [],
  count: 0,
  error: null,
  accessToken: undefined
};
