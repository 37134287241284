import { createSlice } from '@reduxjs/toolkit';
import { ChecklistEmptyState, IChecklistState } from './fetchDefaultChecklistTemplateState';
import { fetchDefaultChecklistTemplate } from './fetchDefaultChecklistTemplateThunk';
import { QueryState } from 'common/enums';

const initialState: IChecklistState = ChecklistEmptyState;

export const fetchDefaultChecklistTemplateSlice = createSlice({
  name: 'fetchChecklist',
  initialState,
  reducers: {
    resetFetchChecklistState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDefaultChecklistTemplate.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchDefaultChecklistTemplate.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.checklist = action.payload;
      })
      .addCase(fetchDefaultChecklistTemplate.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchChecklistState } = fetchDefaultChecklistTemplateSlice.actions;
export default fetchDefaultChecklistTemplateSlice.reducer;
