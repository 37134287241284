import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { ICreateInquiry } from 'interfaces/requirements.interface';

export interface ICreateInquiryState {
  state: QueryState;
  inquiries: Nullable<ICreateInquiry>;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const CreateInquiryEmptyState: ICreateInquiryState = {
  state: QueryState.UNDEFINED,
  inquiries: null,
  error: null,
  accessToken: undefined
};
