import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { updateFiles } from 'services/filesEndPoints';
import { IFileUpdate } from 'interfaces/File';
export const updateFilesEntries = createAsyncThunk(
  'updateFilesEntries',
  async (payload: IFileUpdate[]) => {
    try {
      const endpoint = updateFiles(payload);
      return await apiFetch(endpoint).then((res) => res?.data || null);
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('updateFiles catch:', message);
      throw Error(message);
    }
  }
);
