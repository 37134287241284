import { createSlice } from '@reduxjs/toolkit';
import { deleteNotificationContact } from './deleteNotificationContactThunk';
import { QueryState } from 'common/enums';
import {
  DeleteNotificationContactEmptyState,
  IDeleteNotificationContactState
} from './deleteNotificationContactState';

const initialState: IDeleteNotificationContactState = DeleteNotificationContactEmptyState;

export const deleteNotificationContactSlice = createSlice({
  name: 'deleteNotificationContact',
  initialState,
  reducers: {
    resetDeleteNotificationContactState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(deleteNotificationContact.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(deleteNotificationContact.fulfilled, (state, action) => {
        state.state = QueryState.DELETED;
      })
      .addCase(deleteNotificationContact.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetDeleteNotificationContactState } = deleteNotificationContactSlice.actions;
export default deleteNotificationContactSlice.reducer;
