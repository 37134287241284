import { createTheme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import themeConfig from './config';

const pxToRem = (n: number) => `${n / themeConfig.rootFontSizePx}rem`;
const theme = createTheme();

const typography: TypographyOptions = {
  h1: {
    fontWeight: 600,
    fontSize: pxToRem(32)
  },
  h2: {
    fontWeight: 600,
    fontSize: pxToRem(22)
  },
  h3: {
    fontWeight: 600,
    fontSize: pxToRem(18)
  },
  h4: {
    fontWeight: 600,
    fontSize: pxToRem(16),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(14)
    }
  },
  h5: {
    fontWeight: 600,
    fontSize: pxToRem(14),
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(13)
    }
  },
  h6: {
    fontWeight: 600,
    fontSize: pxToRem(12),
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(11)
    }
  },
  body1: {
    fontSize: pxToRem(11)
  },
  body2: {
    fontSize: pxToRem(10)
  }
};

export default typography;
