const ProjectTypesLabels = [
  { type: '5 Percent Initiative Project', label: '5IP' },
  { type: 'Planning', label: 'PLN' },
  { type: 'Property Acquisition and Structure Demolition', label: 'DEM' },
  { type: 'Property Acquisition and Structure Relocation', label: 'REL' },
  { type: 'Structure Elevation', label: 'ELV' },
  { type: 'Mitigation Reconstruction', label: 'REC' },
  { type: 'Dry Floodproofing of Historic Residential Structures', label: 'FHR' },
  { type: 'Dry Floodproofing of Non-Residential Structures', label: 'FNR' },
  { type: 'Direct Technical Assistance', label: 'DTA' },
  { type: 'Localized Flood Reduction Projects', label: 'LFR' },
  { type: 'Community Safe Room Construction', label: 'CSRC' },
  { type: 'Individual Safe Room Construction', label: 'ISRC' },
  { type: 'Generator', label: 'GEN' },
  { type: 'Infrastructure Retrofit', label: 'RET' },
  { type: 'Structural Retrofitting of Existing Buildings', label: 'RS' },
  { type: 'Non-Structural Retrofitting of Existing Buildings', label: 'RNS' },
  { type: 'Wind Retrofit (w/ Saferoom)', label: 'WR-S' },
  { type: 'Wildfire Mitigation', label: 'WM' },
  { type: 'Soil Stabilization', label: 'SS' },
  { type: 'Post-Disaster Code Enforcement', label: 'PDC' }
];

export default ProjectTypesLabels;
