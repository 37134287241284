import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { deleteNotificationContactEndpoint } from '../../../services/notificationContactEndPoints';
import { INotificationContact } from 'interfaces/notification.interface';

export const deleteNotificationContact = createAsyncThunk(
  'deleteNotificationContact',
  async (id: string) => {
    try {
      const endpoint = deleteNotificationContactEndpoint(id);
      return await apiFetch<Partial<INotificationContact>>(endpoint).then(
        (res) => res?.data || null
      );
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
