import { createSlice } from '@reduxjs/toolkit';
import { CreatePlanEmptyState, ICreatePlanState } from './createPlanState';
import { createPlan } from './createPlanThunk';
import { QueryState } from 'common/enums';

const initialState: ICreatePlanState = CreatePlanEmptyState;

export const createPlanSlice = createSlice({
  name: 'createPlan',
  initialState,
  reducers: {
    resetCreatePlanState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createPlan.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(createPlan.fulfilled, (state) => {
        state.state = QueryState.CREATED;
      })
      .addCase(createPlan.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetCreatePlanState } = createPlanSlice.actions;
export default createPlanSlice.reducer;
