import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { INotificationContact } from 'interfaces/notification.interface';

export interface IFetchNotificationContactsState {
  state: QueryState;
  contacts: INotificationContact[];
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const FetchNotificationContactsEmptyState: IFetchNotificationContactsState = {
  state: QueryState.UNDEFINED,
  contacts: [],
  error: null,
  accessToken: undefined
};
