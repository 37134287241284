import { createSlice } from '@reduxjs/toolkit';
import { JurisdictionsEmptyState, IJurisdictionsState } from './fetchJurisdictionsByPlanIdState';
import { fetchJurisdictionsByPlanId } from './fetchJurisdictionsByPlanIdThunk';
import { QueryState } from 'common/enums';

const initialState: IJurisdictionsState = JurisdictionsEmptyState;

export const fetchJurisdictionsByPlanIdSlice = createSlice({
  name: 'fetchJurisdictionsByPlanId',
  initialState,
  reducers: {
    resetFetchJurisdictionsState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchJurisdictionsByPlanId.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchJurisdictionsByPlanId.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.jurisdictions = action.payload;
      })
      .addCase(fetchJurisdictionsByPlanId.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchJurisdictionsState } = fetchJurisdictionsByPlanIdSlice.actions;
export default fetchJurisdictionsByPlanIdSlice.reducer;
