import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { getContactsAllNotificationsEndpoint } from '../../../services/notificationContactEndPoints';
import { INotificationContact } from 'interfaces/notification.interface';

export const fetchNotificationContactsThunk = createAsyncThunk(
  'fetchNotificationContacts',
  async () => {
    try {
      const endpoint = getContactsAllNotificationsEndpoint();
      const resp = await apiFetch<INotificationContact[]>(endpoint);
      return resp.data;
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
