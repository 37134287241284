import { RootState } from 'store';
export const selectToken = (state: RootState) => state.token;
export const selectPlanById = (state: RootState) => state.plan;
export const selectPlans = (state: RootState) => state.plans;
export const selectCreatePlan = (state: RootState) => state.createPlan;
export const selectUpdatePlan = (state: RootState) => state.updatePlan;
export const selectDefaultChecklistTemplate = (state: RootState) => state.defaultChecklistTemplate;
export const selectDefaultChecklistElements = (state: RootState) => state.defaultChecklistElements;
export const selectCreateElement = (state: RootState) => state.createChecklistElement;
export const selectCreateRequirement = (state: RootState) => state.createChecklistRequirement;
export const selectCreateInquiry = (state: RootState) => state.createChecklistInquiry;
export const selectUpdateRequirement = (state: RootState) => state.updateRequirement;
export const selectJurisdictions = (state: RootState) => state.jurisdictions;
export const selectUpdateJurisdictions = (state: RootState) => state.updateJurisdictions;
export const selectChecklistResponses = (state: RootState) => state.checklistResponses;
export const selectUpdateChecklistResponses = (state: RootState) => state.updateChecklistResponses;
export const selectAdoptionRate = (state: RootState) => state.updatePlanAdoptionRate;
export const selectMenu = (state: RootState) => state.drawerMenu.openDrawer;
export const selectActivePath = (state: RootState) => state.drawerMenu.activePath;
export const selectTimeline = (state: RootState) => state.timeline;
export const selectHomeText = (state: RootState) => state.text;
export const selectUpdateText = (state: RootState) => state.updateText;
export const selectUpdateOrganizations = (state: RootState) => state.updateOrganizations;
export const selectRequirement = (state: RootState) => state.requirement;
export const selectCountRequirements = (state: RootState) => state.countRequirements;
export const selectLocalFiles = (state: RootState) => state.fetchLocalFiles;
export const selectCreateFile = (state: RootState) => state.createFile;
export const selectDocumentsMoveState = (state: RootState) => state.changeDocumentsMoveState;
export const selectFilesUpdateState = (state: RootState) => state.updateFilesEntries;
export const selectCreateProject = (state: RootState) => state.createProject;
export const selectUpdateProject = (state: RootState) => state.updateProject;
export const selectProjectById = (state: RootState) => state.project;
export const selectRequirementTab = (state: RootState) => state.requirementsTabView;
export const selectUpdateElement = (state: RootState) => state.updateChecklistElement;
export const selectDeleteElement = (state: RootState) => state.deleteChecklistElement;
export const selectDeleteRequirement = (state: RootState) => state.deleteChecklistRequirement;
export const selectSupportMessage = (state: RootState) => state.supportMessage;
export const selectAllProjects = (state: RootState) => state.fetchAllProjects;
export const selectCountProjectsByType = (state: RootState) => state.fetchCountProjectsByType;
export const selectUserAccount = (state: RootState) => state.userInfo.userAccount;
export const selectCognitoUser = (state: RootState) => state.cognito.cognitoUser;
export const selectDuplicateArchivePlan = (state: RootState) => state.duplicateArchivePlan;
export const selectProjectTypes = (state: RootState) => state.fetchProjectTypes;
export const selectManageColumns = (state: RootState) => state.fetchManageColumns;
export const selectProjectsAndTypes = (state: RootState) => state.fetchProjectsAndTypes;
export const selectMapLatLngZoom = (state: RootState) => state.mapLatLngZoom;
export const selectFetchUsers = (state: RootState) => state.fetchUsers;
export const selectFetchNotificationContacts = (state: RootState) => state.notificationContacts;
export const selectTokenVerification = (state: RootState) => state.verifyToken;
export const selectFetchPalmettoUsers = (state: RootState) => state.fetchPalmettoUsers;
export const selectDeletePlan = (state: RootState) => state.deletePlan;
