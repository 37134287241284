const GrantProgramsList = [
  { label: 'BRIC', value: 'BRIC' },
  { label: 'HMGP', value: 'HMGP' },
  { label: 'LPDM', value: 'LPDM' },
  { label: 'PDM', value: 'PDM' },
  {
    label: 'Safeguarding Tomorrow Revolving Loan Fund',
    value: 'STRLF'
  }
];

export default { GrantProgramsList };
