//PALMETTO ACCOUNT
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ITokenState } from './TokenState';
import { authLogin } from 'services/authEndPoints';
import apiFetch from 'services/apiFetch';
import { ILoginResponse } from 'interfaces/auth.interface';

export const userLogin = createAsyncThunk(
  'token/userLogin',
  async (params: { username: string; password: string }) => {
    try {
      const endpoint = authLogin(params.username, params.password);

      const resp = await apiFetch<ILoginResponse>(endpoint).then((res) => {
        return res?.data || null;
      });

      if (resp && resp.data) {
        const payload: ITokenState = {
          jwToken: null,
          authorization: resp.data.id,
          ttl: resp.data.ttl,
          createdAt: resp.data.createdAt,
          user: resp.data.user
        };
        return payload;
      } else {
        throw Error('Internal Server Error');
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else message = String(error);
      throw Error(message);
    }
  }
);
