import { createSlice } from '@reduxjs/toolkit';
import { deletePlan } from './deletePlanThunk';
import { QueryState } from 'common/enums';
import { DeletePlanEmptyState, IDeletePlanState } from './deletePlanState';

const initialState: IDeletePlanState = DeletePlanEmptyState;

export const deletePlanSlice = createSlice({
  name: 'deletePlan',
  initialState,
  reducers: {
    resetDeletePlanState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(deletePlan.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(deletePlan.fulfilled, (state, action) => {
        state.state = QueryState.DELETED;
      })
      .addCase(deletePlan.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetDeletePlanState } = deletePlanSlice.actions;
export default deletePlanSlice.reducer;
