import { createSlice } from '@reduxjs/toolkit';
import { duplicateAndArchivePlanThunk } from './duplicateAndArchivePlanThunk';
import { QueryState } from 'common/enums';
import {
  DuplicateArchivePlanEmptyState,
  IDuplicateArchivePlanState
} from './duplicateAndArchivePlanState';

const initialState: IDuplicateArchivePlanState = DuplicateArchivePlanEmptyState;

export const duplicateAndArchivePlan = createSlice({
  name: 'duplicateArchivePlan',
  initialState,
  reducers: {
    resetDuplicateArchivePlanState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(duplicateAndArchivePlanThunk.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(duplicateAndArchivePlanThunk.fulfilled, (state, action) => {
        state.state = QueryState.UPDATED;
      })
      .addCase(duplicateAndArchivePlanThunk.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetDuplicateArchivePlanState } = duplicateAndArchivePlan.actions;
export default duplicateAndArchivePlan.reducer;
