import { createAsyncThunk } from '@reduxjs/toolkit';
import { ITimeline } from 'interfaces/timeline.interface';
import apiFetch from 'services/apiFetch';
import { getTimelineByPlanId } from 'services/timelineEndPoints';

export const fetchTimelineByPlanId = createAsyncThunk(
  'timeline/fetchByPlanId',
  async (id: string) => {
    try {
      const endpoint = getTimelineByPlanId(id);
      const resp = await apiFetch<ITimeline[]>(endpoint).then((res) => res?.data || null);
      return resp;
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
