import { Nullable } from 'common/utils/Nullable';
import { IUserMitigation } from 'interfaces/userinfo.interface';

export interface ICurrentUserState {
  userAccount: Partial<IUserMitigation> | null;
  loading: boolean;
  success: boolean;
  isSaving: boolean;
  error: Nullable<string>;
}

export const CurrentUserEmptyState: ICurrentUserState = {
  userAccount: null,
  loading: false,
  success: false,
  isSaving: false,
  error: null
};
