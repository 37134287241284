import { createSlice } from '@reduxjs/toolkit';
import { TokenEmptyState, ITokenState } from './verifyTokenState';
import { verifyToken } from './verifyTokenThunk';
import { QueryState } from 'common/enums';

const initialState: ITokenState = TokenEmptyState;

export const verifyTokenSlice = createSlice({
  name: 'verifyToken',
  initialState,
  reducers: {
    resetVerifyTokenState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(verifyToken.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(verifyToken.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.tokenVerification = action.payload;
      })
      .addCase(verifyToken.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetVerifyTokenState } = verifyTokenSlice.actions;
export default verifyTokenSlice.reducer;
