import React from 'react';
import ReactSelect, {
  MultiValueRemoveProps,
  components,
  CSSObjectWithLabel,
  ControlProps,
  GroupBase
} from 'react-select';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutline';
import { ISelectProp } from 'interfaces/SelectProp';

interface IMultiProps {
  disabled?: boolean;
  white?: boolean;
  error: boolean;
  options: ISelectProp[];
  value: ISelectProp[] | undefined;
  onChange: (newValue: ISelectProp[]) => void;
  scrollIntoView?: boolean;
  className?: string;
}

type MultiProps = IMultiProps;

const MultiSelectView: React.FC<MultiProps> = ({
  white = false,
  scrollIntoView = true,
  ...props
}) => {
  //  eslint-disable-next-line
  const MultiValueRemove = (props: MultiValueRemoveProps<any>) => {
    return (
      <components.MultiValueRemove {...props}>
        <RemoveCircleIcon sx={{ fontSize: '1em' }} />
      </components.MultiValueRemove>
    );
  };

  type StylesType = Record<
    string,
    (
      base: (props?: Record<string, CSSObjectWithLabel>) => CSSObjectWithLabel,
      state: ControlProps<ISelectProp, boolean, GroupBase<ISelectProp>>
    ) => CSSObjectWithLabel
  >;

  const selectStyles: StylesType = {
    control: (styles) =>
      props.error
        ? { ...styles, backgroundColor: `${white ? '#ffff' : '#fafafa'}`, borderColor: 'red' }
        : { ...styles, backgroundColor: `${white ? '#ffff' : '#fafafa'}` },
    option: (styles) => {
      return { ...styles };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: '#D8D8D8',
        borderRadius: '2px'
      };
    },
    multiValueLabel: (styles) => {
      return { ...styles, color: '#1A1A1A', fontWeight: '700' };
    },
    multiValueRemove: (styles) => {
      return {
        ...styles,
        color: '#1A1A1A',
        borderRadius: '2px',
        cursor: 'pointer',
        ':hover': { color: '#01195B' }
      };
    }
  };

  const onMenuOpen = () => {
    if (scrollIntoView) {
      setTimeout(() => {
        const elClassName = props.className ? props.className : 'selectDropdown';
        const selectedEl = document.getElementsByClassName(elClassName)[0];
        selectedEl?.scrollIntoView();
      }, 15);
    }
  };

  return (
    <ReactSelect
      {...props}
      isDisabled={props.disabled}
      isMulti
      hideSelectedOptions={true}
      options={props.options}
      components={{ MultiValueRemove }}
      styles={selectStyles}
      value={props.value ? props.value : null}
      // eslint-disable-next-line
      onChange={(selected: ISelectProp[] | any) => {
        return selected?.length && selected.find((option: ISelectProp) => option.value === 'all')
          ? props.onChange(props.options.slice(1))
          : props.onChange(selected);
      }}
      className={props.className ? props.className : 'selectDropdown'}
      onMenuOpen={onMenuOpen}
    />
  );
};

export default MultiSelectView;
