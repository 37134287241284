// noinspection JSUnusedGlobalSymbols

export enum ProgramCodes {
  PEOC = 'PEOC',
  LEMPG = 'LEMPG',
  MITG = 'MITG',
  SERCLEPC = 'SERCLEPC',
  PTRACK = 'PTRACK',
  PTRAIN = 'PTRAIN',
  SUPPTL = 'SUPPTL',
  EGRESS = 'EGRESS',
  DMA = 'DMA',
  CDOWN = 'CDOWN',
  REP = 'REP',
  VEOC = 'VEOC'
}

export enum ProgramTitles {
  PEOC = 'Palmetto EOC',
  LEMPG = 'LEMPG',
  MITG = 'Mitigation',
  SERCLEPC = 'SERC/LEPC',
  PTRACK = 'Patient Tracking',
  PTRAIN = 'Palmetto Training',
  SUPPTL = 'Supplemental',
  EGRESS = 'Entry / Egress',
  DMA = 'Main Damage Assessment',
  CDOWN = 'Calldown',
  REP = 'Radiological Emergency Preparedness',
  VEOC = 'Virtual EOC'
}

export enum SiteStatusCodes {
  ACTIVE = 'ACT',
  REQUEST = 'REQ',
  DENY = 'DNY',
  SUSPEND = 'SUS'
}

export enum ProgramSites {
  LEMPG = 'https://lempg.palmettoeoc.com/',
  MITG = 'https://mitigation.palmettoeoc.com/',
  SERCLEPC = 'https://lepc.palmettoeoc.com/',
  PTRACK = 'https://emt.palmettoeoc.com/',
  PTRAIN = 'https://www.palmettoeoc.com/',
  SUPPTL = 'https://www.palmettoeoc.com/',
  EGRESS = 'https://scan.palmettoeoc.com/',
  DMA = 'https://damage.palmettoeoc.com/',
  CDOWN = 'https://calldown.palmettoeoc.com/',
  REP = 'https://rep.palmettoeoc.com/',
  VEOC = 'https://veoc.palmettoeoc.com/'
}

export default ProgramCodes;
