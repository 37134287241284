import { createSlice } from '@reduxjs/toolkit';
import { updateTimeline } from './updateTimelineItemThunk';
import { QueryState } from 'common/enums';
import { IUpdateTimelineState, UpdateTimelineEmptyState } from './updateTimelineItemState';

const initialState: IUpdateTimelineState = UpdateTimelineEmptyState;

export const updateTimelineSlice = createSlice({
  name: 'updateTimeline',
  initialState,
  reducers: {
    resetUpdateTimelineState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(updateTimeline.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(updateTimeline.fulfilled, (state) => {
        state.state = QueryState.CREATED;
      })
      .addCase(updateTimeline.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});
export const { resetUpdateTimelineState } = updateTimelineSlice.actions;
export default updateTimelineSlice.reducer;
