import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { IUpdateRequirement } from 'interfaces/requirements.interface';

export interface IUpdateRequirementState {
  state: QueryState;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const UpdateRequirementEmptyState: IUpdateRequirementState = {
  state: QueryState.UNDEFINED,
  error: null,
  accessToken: undefined
};
