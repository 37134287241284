//COGNITO
// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAppDispatch } from 'store/hooks';
// import { Nullable } from 'common/utils';
// import { LoginProps, LoginUser } from './Login.props';
// import LoginView from './Login.view';
// import { updateUserLogin } from 'store/entities/cognito/cognitoSlice';
// import { cognitoLogin } from 'store/entities/cognito/cognitoThunk';
// import { ITokenState } from 'store/entities/userAuth/TokenState';
// import { updateToken } from 'store/entities/userAuth/tokenSlice';
// import { updateCurrentUser } from 'store/entities/currentUser/currentUserSlice';
//
// const LoginErrMessage = {
//   MISSING: 'Please enter the username and password',
//   INVALID: 'You have entered an invalid username and/or password',
//   SERVER: 'Sorry, something went wrong there. Please try again.'
// };
//
// const LoginContainer: React.FC = () => {
//   const dispatch = useAppDispatch();
//   const navigate = useNavigate();
//   const [errMessage, setErrMessage] = useState<Nullable<string>>(null);
//   const [login, setLogin] = useState<Partial<LoginUser>>({});
//
//   const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
//     const field = e.currentTarget.name;
//     setLogin({ ...login, [field]: e.currentTarget.value });
//     setErrMessage(null);
//   };
//
//   const handleLogin = async () => {
//     const { username, password } = login;
//     if (username && password) {
//       dispatch(updateUserLogin({ username, password }));
//       const loginData = await dispatch(cognitoLogin({ username, password })).unwrap();
//       if (loginData) {
//         if (loginData?.accountInfo) {
//           const userInfo = loginData?.accountInfo;
//
//           const payload: ITokenState = {
//             authorization: userInfo.accessToken,
//             jwToken: loginData.jwtToken,
//             email: userInfo.email,
//             createdAt: 0,
//             ttl: 0,
//             user: userInfo.username
//           };
//           dispatch(updateToken(payload));
//           if (userInfo?.id) {
//             dispatch(updateCurrentUser(userInfo));
//             navigate(`${process.env.REACT_APP_APP_URL_PATH}`);
//             setErrMessage(null);
//           } else {
//             console.log('THIS IS INVALID');
//             setErrMessage(LoginErrMessage.SERVER);
//           }
//         }
//       }
//     }
//   };
//
//   const combinedProps: LoginProps = {
//     handleChange,
//     login,
//     errMessage,
//     handleLogin
//   };
//
//   return <LoginView {...combinedProps} />;
// };
//
// export default LoginContainer;

//PALMETTO ACCOUNT
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userLogin } from 'store/entities/userAuth/tokenThunk';
import { Nullable } from 'common/utils';
import { LoginProps, LoginUser } from './Login.props';
import LoginView from './Login.view';
import { updateCurrentUser } from 'store/entities/currentUser/currentUserSlice';
import { IUserMitigation } from 'interfaces/userinfo.interface';
import { selectTokenVerification } from '../../store/selectors';
import { QueryState } from '../../common/enums';

const LoginErrMessage = {
  MISSING: 'Please enter the username and password',
  INVALID: 'You have entered an invalid username and/or password',
  SERVER: 'Sorry, something went wrong there. Please try again.'
};

const LoginContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [errMessage, setErrMessage] = useState<Nullable<string>>(null);
  const [login, setLogin] = useState<Partial<LoginUser>>({});
  const verifyTokenStore = useAppSelector(selectTokenVerification);
  const loading = verifyTokenStore.state === QueryState.AWAIT;
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const field = e.currentTarget.name;
    setLogin({ ...login, [field]: e.currentTarget.value });
    setErrMessage(null);
  };
  //COGNITO
  // const handleLogin = async () => {
  //   const { username, password } = login;
  //   if (username && password) {
  //     dispatch(updateUserLogin({ username, password }));
  //     const loginData = await dispatch(cognitoLogin({ username, password })).unwrap();
  //     if (loginData) {
  //       if (loginData?.accountInfo) {
  //         const userInfo = loginData?.accountInfo;
  //
  //         const payload: ITokenState = {
  //           authorization: userInfo.accessToken,
  //           jwToken: loginData.jwtToken,
  //           email: userInfo.email,
  //           createdAt: 0,
  //           ttl: 0,
  //           user: userInfo.username
  //         };
  //         dispatch(updateToken(payload));
  //         if (userInfo?.id) {
  //           dispatch(updateCurrentUser(userInfo));
  //           navigate(`${process.env.REACT_APP_APP_URL_PATH}`);
  //           setErrMessage(null);
  //         } else {
  //           console.log('THIS IS INVALID');
  //           setErrMessage(LoginErrMessage.SERVER);
  //         }
  //       }
  //     }
  //   }
  // };

  const handleLogin = async () => {
    const { username, password } = login;
    let response;
    if (username && password) {
      response = await dispatch(userLogin({ username, password })).unwrap();
      let userPayload = {} as Partial<IUserMitigation>;
      if (response && response.user) {
        userPayload = {
          id: response.user.id,
          name: response.user.name,
          email: response.user.email
        };
        dispatch(updateCurrentUser(userPayload));
      }
    }

    if (response) {
      navigate(`${process.env.REACT_APP_APP_URL_PATH}`);
      setErrMessage(null);
    } else {
      setErrMessage(LoginErrMessage.SERVER);
    }
  };

  const combinedProps: LoginProps = {
    handleChange,
    login,
    errMessage,
    handleLogin,
    loading
  };

  return <LoginView {...combinedProps} />;
};

export default LoginContainer;
