import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session';
import tokenReducer from 'store/entities/userAuth/tokenSlice';
import fetchPlanByIdReducer from 'store/entities/fetchPlanById/fetchPlanByIdSlice';
import createPlanReducer from 'store/entities/createPlan/createPlanSlice';
import updatePlanReducer from 'store/entities/updatePlan/updatePlanSlice';
import fetchDefaultChecklistTemplate from 'store/entities/fetchDefaultChecklistTemplate/fetchDefaultChecklistTemplateSlice';
import fetchDefaultChecklistElement from 'store/entities/fetchDefaultChecklistElements/fetchDefaultChecklistElementsSlice';
import fetchJurisdictionsByPlanId from 'store/entities/fetchJurisdictionsByPlanId/fetchJurisdictionsByPlanIdSlice';
import updateJurisdictions from 'store/entities/updateJurisdiction/updateJurisdictionSlice';
import updateOrganizations from 'store/entities/updatePlanOrganization/updatePlanOrganizationSlice';
import fetchChecklistResponses from './entities/fetchChecklistResponses/fetchChecklistResponsesSlice';
import updateChecklistResponsesSliceReducer from './entities/updateChecklistResponses/updateChecklistResponsesSlice';
import updatePlanAdoptionRate from './entities/updatePlanAdoptionRate/updatePlanAdoptionRateSlice';
import drawerMenuSlice from './entities/drawerMenu/drawerMenuSlice';
import fetchTimelineByPlanId from './entities/fetchTimelineByPlanId/fetchTimelineByPlanIdSlice';
import addTimelineReducer from './entities/addTimelineItem/addTimelineItemSlice';
import updateTimelineReducer from './entities/updateTimelineItem/updateTimelineItemSlice';
import fetchHomeText from './entities/fetchHomeText/fetchHomeTextSlice';
import updateHomeText from './entities/updateHomeText/updateHomeTextSlice';
import createElementReducer from './entities/createElement/createElementSlice';
import createRequirementReducer from './entities/createRequirement/createRequirementSlice';
import createInquiryReducer from './entities/createInquiry/createInquirySlice';
import fetchRequirementByIdReducer from './entities/fetchRequirementById/fetchRequirementByIdSlice';
import fetchCountElemReq from './entities/fetchElemReqCount/fetchElementRequirementsCountSlice';
import updateRequirementReducer from './entities/updateRequirement/updateRequirementSlice';
import createFileReducer from './entities/createFile/createFileSlice';
import fetchLocalFiles from './entities/fetchLocalFiles/fetchLocalFilesSlice';
import changeDocumentsMoveState from './entities/documentsMove/documentsMoveSlice';
import updateFilesEntries from './entities/updateFiles/updateFilesSlice';
import createProjectReducer from './entities/createProject/createProjectSlice';
import updateProjectReducer from './entities/updateProject/updateProjectSlice';
import fetchProjectByIdReducer from './entities/fetchProjectById/fetchProjectByIdSlice';
import requirementsTableViewSlice from './entities/requirementsTableView/requirementTableViewSlice';
import updateElementReducer from './entities/updateElement/updateElementSlice';
import deleteElementReducer from './entities/deleteElement/deleteElementSlice';
import deleteRequirementReducer from './entities/deleteRequirement/deleteRequirementSlice';
import createSupportMessageReducer from './entities/createSupportMessage/createSupportMessageSlice';
import fetchAllProjectsReducer from './entities/fetchAllProjects/fetchAllProjectsSlice';
import fetchCountProjectsByType from './entities/fetchCountProjectsByType/fetchCountProjectsByTypeSlice';
import fetchAllPlansReducer from './entities/fetchAllPlans/fetchAllPlansSlice';
import deleteFileReducer from './entities/deleteFile/deleteFileSlice';
import cognitoReducer from './entities/cognito/cognitoSlice';
import currentUserReducer from './entities/currentUser/currentUserSlice';
import duplicateArchivePlanReducer from './entities/duplicateAndArchivePlan/duplicateAndArchivePlanSlice';
import fetchProjectTypesReducer from './entities/fetchProjectTypes/fetchProjectTypesSlice';
import fetchManageColumnsReducer from './entities/fetchManageColumns/fetchManageColumnsSlice';
import fetchProjectsAndTypesReducer from './entities/fetchProjectsAndTypes/fetchProjectsAndTypesSlice';
import mapLatLngZoomSlice from './entities/mapLatLngZoom/mapLatLngZoomSlice';
import fetchUsersSlice from './entities/fetchUsers/fetchUsersSlice';
import fetchNotificationContacts from './entities/fetchNotificationContacts/fetchNotificationContactsSlice';
import createNotificationContact from './entities/createNotificationContact/createNotificationContactSlice';
import updateNotificationContact from './entities/updateNotificationContact/updateNotificationContactSlice';
import deleteNotificationContact from './entities/deleteNotificationContact/deleteNotificationContactSlice';
import verifyToken from './entities/verifyToken/verifyTokenSlice';
import fetchPalmettoUsers from './entities/fetchPalmettoUsers/fetchPalmettoUsersSlice';
import deletePlanReducer from './entities/deletePlan/deletePlanSlice';
import archivePlanReducer from './entities/archivePlan/archivePlanSlice';

// const rootPersistConfig = {
//   key: 'root',
//   version: 1,
//   storage,
//   whitelist: ['token']
// };
//
// const userPersistConfig = {
//   key: 'userInfo',
//   storage: storageSession
// };

const rootReducer = combineReducers({
  token: tokenReducer,
  plan: fetchPlanByIdReducer,
  plans: fetchAllPlansReducer,
  createPlan: createPlanReducer,
  updatePlan: updatePlanReducer,
  defaultChecklistTemplate: fetchDefaultChecklistTemplate,
  defaultChecklistElements: fetchDefaultChecklistElement,
  createChecklistElement: createElementReducer,
  createChecklistRequirement: createRequirementReducer,
  createChecklistInquiry: createInquiryReducer,
  jurisdictions: fetchJurisdictionsByPlanId,
  updateJurisdictions: updateJurisdictions,
  checklistResponses: fetchChecklistResponses,
  updateChecklistResponses: updateChecklistResponsesSliceReducer,
  updatePlanAdoptionRate: updatePlanAdoptionRate,
  drawerMenu: drawerMenuSlice,
  timeline: fetchTimelineByPlanId,
  addTimelineItem: addTimelineReducer,
  updateTimelineItem: updateTimelineReducer,
  text: fetchHomeText,
  updateText: updateHomeText,
  updateOrganizations: updateOrganizations,
  requirement: fetchRequirementByIdReducer,
  countRequirements: fetchCountElemReq,
  updateRequirement: updateRequirementReducer,
  createFile: createFileReducer,
  fetchLocalFiles: fetchLocalFiles,
  changeDocumentsMoveState: changeDocumentsMoveState,
  updateFilesEntries: updateFilesEntries,
  createProject: createProjectReducer,
  updateProject: updateProjectReducer,
  project: fetchProjectByIdReducer,
  requirementsTabView: requirementsTableViewSlice,
  updateChecklistElement: updateElementReducer,
  deleteChecklistElement: deleteElementReducer,
  deleteChecklistRequirement: deleteRequirementReducer,
  supportMessage: createSupportMessageReducer,
  fetchAllProjects: fetchAllProjectsReducer,
  fetchCountProjectsByType: fetchCountProjectsByType,
  deleteFile: deleteFileReducer,
  cognito: cognitoReducer,
  userInfo: currentUserReducer,
  duplicateArchivePlan: duplicateArchivePlanReducer,
  fetchProjectTypes: fetchProjectTypesReducer,
  fetchManageColumns: fetchManageColumnsReducer,
  fetchProjectsAndTypes: fetchProjectsAndTypesReducer,
  mapLatLngZoom: mapLatLngZoomSlice,
  fetchUsers: fetchUsersSlice,
  notificationContacts: fetchNotificationContacts,
  createNotificationContact: createNotificationContact,
  updateNotificationContact: updateNotificationContact,
  deleteNotificationContact: deleteNotificationContact,
  verifyToken: verifyToken,
  fetchPalmettoUsers: fetchPalmettoUsers,
  deletePlan: deletePlanReducer,
  archivePlan: archivePlanReducer
});

// const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
