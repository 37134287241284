import { QueryState } from '../../../common/enums';
import { Nullable } from '../../../common/utils';
import { IManageColumns } from 'interfaces/project.interface';

export interface IManageColumnsState {
  state: QueryState;
  manageColumns: IManageColumns;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const FetchManageColumnsEmptyState: IManageColumnsState = {
  state: QueryState.UNDEFINED,
  manageColumns: {} as IManageColumns,
  error: null,
  accessToken: undefined
};
