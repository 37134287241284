import { createSlice } from '@reduxjs/toolkit';
import { QueryState } from 'common/enums';
import { FetchUsersEmptyState, IFetchUsersState } from './fetchUsersState';
import { fetchUsers } from './fetchUsersThunk';

const initialState: IFetchUsersState = FetchUsersEmptyState;

export const fetchUsersSlice = createSlice({
  name: 'fetchUsers',
  initialState,
  reducers: {
    resetFetchUsersState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchUsersState } = fetchUsersSlice.actions;
export default fetchUsersSlice.reducer;
