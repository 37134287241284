import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { IChecklistElementDetails } from 'interfaces/checklist.interface';

export interface IChecklistElementState {
  state: QueryState;
  elements: IChecklistElementDetails[];
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const ChecklistElementEmptyState: IChecklistElementState = {
  state: QueryState.UNDEFINED,
  elements: [],
  error: null,
  accessToken: undefined
};
