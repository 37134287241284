import { SelectedView } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
export interface IRequirementTableState {
  selectedView: SelectedView;
  elementId: string;
  elementIndex: Nullable<number>;
}

export const DefaultRequirementTableState: IRequirementTableState = {
  selectedView: SelectedView.ELEMENTS,
  elementId: '',
  elementIndex: null
};
