import { QueryState } from '../../../common/enums';
import { Nullable } from '../../../common/utils';
import { IHomeText } from 'common/interfaces/Home.interface';
export interface IHomeTextState {
  state: QueryState;
  text: IHomeText;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const HomeTextEmptyState: IHomeTextState = {
  state: QueryState.UNDEFINED,
  text: {
    id: '',
    homepageText: '',
    isDefault: false
  },
  error: null,
  accessToken: undefined
};
