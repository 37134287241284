import { createAsyncThunk } from '@reduxjs/toolkit';

import { IPlanDetails } from 'interfaces/plan.interface';
import apiFetch from 'services/apiFetch';
import { createPlanEndpoint } from 'services/plansEndPoints';
export const createPlan = createAsyncThunk('plans/createPlan', async (data: IPlanDetails) => {
  try {
    const endpoint = createPlanEndpoint(data);
    return await apiFetch<Partial<IPlanDetails>>(endpoint).then((res) => res?.data || null);
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('createPlan catch:', message);
    throw Error(message);
  }
});
