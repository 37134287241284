import React, { useState } from 'react';
import PalmettoSideMenu from '@zawarski/palmetto-react-side-menu';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import GuideIcon from '@mui/icons-material/MenuBook';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import MapIcon from '@mui/icons-material/Map';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectActivePath, selectMenu, selectUserAccount } from '../../../store/selectors';
import Logo from 'assets/images/palmettoLogo.png';
import { changeMenu, changeActivePath } from '../../../store/entities';
import { useNavigate } from 'react-router-dom';
import { AccessTypes } from 'common/enums';

const PalmettoSideMenuComponent: React.FC = () => {
  const isDrawerOpen = useAppSelector(selectMenu);
  const activePath = useAppSelector(selectActivePath);
  const [activeSubMenu, setActiveSubMenu] = useState(false);
  const [restrictedItems, setRestrictedItems] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectUserAccount);
  const UserFullName =
    currentUser?.name ||
    currentUser?.pvPersonGivenName +
      (currentUser?.pvPersonSurName ? ' ' + currentUser.pvPersonSurName : '');
  const userAccessType = currentUser?.accessType;

  const restrictedMenuItems = (accessType: string) => {
    const restrictedItems = [];
    if (accessType !== AccessTypes.FULL_ACCESS) {
      restrictedItems.push('requirements');
    }
    return restrictedItems;
  };

  const restricted = restrictedMenuItems(userAccessType || '');

  const onDownloadPdf = () => {
    const a = document.createElement('a');
    a.href = 'pdf/MitigationSCSiteGuide.pdf';
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    toggleSlideMenu();
  };

  const menuItems = {
    // This is the top section of the menu
    menu: [
      {
        key: 'mitigation',
        name: 'Home',
        path: `${process.env.REACT_APP_APP_URL_PATH}`,
        startIcon: <HomeIcon />
      },
      {
        key: 'requirements',
        name: 'Planning Requirements',
        path: `${process.env.REACT_APP_APP_URL_PATH}/requirements`,
        startIcon: <ReportProblemIcon />
      },
      {
        key: 'plans',
        name: 'Plans',
        path: `${process.env.REACT_APP_APP_URL_PATH}/plans`,
        startIcon: <InsertDriveFileIcon />
      },
      {
        key: 'projects',
        name: 'Projects',
        path: `${process.env.REACT_APP_APP_URL_PATH}/projects`,
        startIcon: <FolderIcon />
      },
      {
        key: 'map',
        name: 'Map',
        path: `${process.env.REACT_APP_APP_URL_PATH}/map`,
        startIcon: <MapIcon />
      }
    ].filter((item) => !restricted.includes(item.key)),
    // This is the bottom section of the menu
    section: [
      {
        key: 'user', // this is the unique key identifier
        name: UserFullName, // this is the name that will be displayed in the menu
        path: '', // this is the path to the page
        startIcon: <PersonIcon />
        // endIcon: <SettingsIcon sx={{ color: 'blue' }} />
      },
      {
        key: 'contact',
        name: 'Contact Support',
        path: '/contact-support',
        endIcon: <LiveHelpIcon sx={{ color: 'blue' }} />
      },
      {
        key: 'guide',
        name: 'View user guide',
        path: `${process.env.REACT_APP_APP_URL_PATH}/user-guide`,
        endIcon: <GuideIcon sx={{ color: 'blue' }} />
      },
      {
        key: 'logout',
        name: 'Log out',
        path: `${process.env.REACT_APP_APP_URL_PATH}/logout`,
        endIcon: <LogoutIcon sx={{ color: 'red' }} />
      }
    ],
    // This is the version section of the menu
    version: {
      name: 'Version 2.0.0', // Define the version number
      color: '#999' // Define the default color
    }
  };

  const toggleSlideMenu = () => {
    dispatch(changeMenu({ BtnAction: !isDrawerOpen }));
  };

  const handleMenuClick = (link: string) => {
    let activePath = link;
    const startIndex = link.indexOf('/mitigation');
    if (startIndex !== -1 && link.length > '/mitigation'.length) {
      activePath = link.slice(0, startIndex) + link.slice(startIndex + '/mitigation'.length);
    }
    if (activePath.includes('user-guide')) {
      onDownloadPdf();
    } else {
      dispatch(changeActivePath({ activePath: activePath }));
      if (link) navigate([link].filter(Boolean).join('/'), { state: null });
    }
    toggleSlideMenu();
  };

  const handleDrawerClose = () => {
    toggleSlideMenu();
  };

  const handleDeactivateSubMenu = () => setActiveSubMenu(false);

  // useEffect(() => {
  //   if (userAccessType) setRestrictedItems(restrictedMenuItems(userAccessType));
  // }, [userAccessType]);

  return (
    <PalmettoSideMenu
      srcLogo={Logo}
      menuItems={menuItems}
      isDrawerOpen={isDrawerOpen}
      activeSubMenu={activeSubMenu}
      activePath={activePath}
      programTitle={'MitigationSC'}
      deactivateSubMenu={handleDeactivateSubMenu}
      onDrawerClose={handleDrawerClose}
      onMenuClick={handleMenuClick}
    />
  );
};

export default PalmettoSideMenuComponent;
