import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';

export interface ICreatePlanState {
  state: QueryState;
  error: Nullable<string>;
  accessToken: string | undefined;
  isEmailDuplicate: boolean;
}

export const CreatePlanEmptyState: ICreatePlanState = {
  state: QueryState.UNDEFINED,
  error: null,
  accessToken: undefined,
  isEmailDuplicate: false
};
