import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../common/enums/AuthProtect';
import { IJurisdiction } from 'interfaces/jurisdiction.interface';

export const getJurisdictionsByPlanId = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `jurisdictions/${id}`
  };
};

export const updateJurisdictionEndpoint = (data: IJurisdiction[]): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: 'jurisdictions',
    data
  };
};
