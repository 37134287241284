import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { IChecklistDetails, IChecklistElementDetails } from 'interfaces/checklist.interface';

export interface IChecklistState {
  state: QueryState;
  checklist: IChecklistDetails;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const ChecklistEmptyState: IChecklistState = {
  state: QueryState.UNDEFINED,
  checklist: {
    id: '',
    name: '',
    isDefault: false,
    checklistElement: []
  },
  error: null,
  accessToken: undefined
};
