import { createAsyncThunk } from '@reduxjs/toolkit';
import { updatePlanAdoptionRateEndpoint } from '../../../services/plansEndPoints';
import apiFetch from '../../../services/apiFetch';

export const updatePlanAdoptionRate = createAsyncThunk(
  'plans/updateAdoptionRate',
  async (id: string) => {
    try {
      const endpoint = updatePlanAdoptionRateEndpoint(id);
      return await apiFetch(endpoint).then((res) => res?.data || null);
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('updatePlanAdoptionRate catch:', message);
      throw Error(message);
    }
  }
);
