const ProjectColor: { [key: string]: string } = {
  '5 Percent Initiative Project': '#a6cee3',
  Planning: '#ffff99',
  'Property Acquisition and Structure Demolition': '#b2df8a',
  'Property Acquisition and Structure Relocation': '#8dd3c7',
  'Structure Elevation': '#33a02c',
  'Mitigation Reconstruction': '#FFFF00',
  'Dry Floodproofing of Historic Residential Structures': '#fb9a99',
  'Dry Floodproofing of Non-Residential Structures': '#e31a1c',
  'Localized Flood Reduction Projects': '#cab2d6',
  'Community Safe Room Construction': '#1f78b4',
  'Individual Safe Room Construction': '#FF5F1F',
  Generator: '#FF33F0',
  'Infrastructure Retrofit': '#387881',
  'Structural Retrofitting of Existing Buildings': '#006d2c',
  'Non-Structural Retrofitting of Existing Buildings': '#808080',
  'Wind Retrofit (w/ Saferoom)': '#000000',
  'Wildfire Mitigation': '#7f0000',
  'Soil Stabilization': '#fdb462',
  'Post-Disaster Code Enforcement': '#6a3d9a',
  'Direct Technical Assistance': '#9b335f'
};

export default ProjectColor;
