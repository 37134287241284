import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICognitoState, CognitoEmptyState } from './cognitoState';
import { cognitoLogin, cognitoChangePassword, refreshCognitoLogin } from './cognitoThunk';

const initialState: ICognitoState = CognitoEmptyState;

export const cognitoSlice = createSlice({
  name: 'cognito',
  initialState,
  reducers: {
    updateActiveGroupId: (state, action: PayloadAction<{ id: number }>) => {
      state.activeGroupId = action.payload.id;
    },
    updateUserLogin: (state, action: PayloadAction<{ username: string; password: string }>) => {
      state.username = action.payload.username;
      state.password = action.payload.password;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(cognitoLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(cognitoLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.idToken = action.payload.jwtToken;
        state.cognitoUser = action.payload.cognitoUser;
        state.loginAccount = action.payload.accountInfo;
        state.activeGroupId = action.payload.activeGroupId || 0;
      })
      .addCase(cognitoLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(cognitoChangePassword.pending, (state) => {
        state.error = null;
        state.inProcess = true;
        state.success = false;
      })
      .addCase(cognitoChangePassword.fulfilled, (state) => {
        state.inProcess = false;
        state.success = true;
      })
      .addCase(cognitoChangePassword.rejected, (state, action) => {
        state.inProcess = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(refreshCognitoLogin.fulfilled, (state, action) => {
        if (action.payload) {
          state.cognitoUser = action.payload.cognitoUser;
          state.idToken = action.payload.jwtToken;
        }
      });
  }
});

export const { updateActiveGroupId, updateUserLogin } = cognitoSlice.actions;

export default cognitoSlice.reducer;
