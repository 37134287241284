import { createSlice } from '@reduxjs/toolkit';
import { updateNotificationContactThunk } from './updateNotificationContactThunk';
import { QueryState } from 'common/enums';
import {
  UpdateNotificationContactEmptyState,
  IUpdateNotificationContactState
} from './updateNotificationContactState';

const initialState: IUpdateNotificationContactState = UpdateNotificationContactEmptyState;

export const updateNotificationContactSlice = createSlice({
  name: 'updateNotificationContact',
  initialState,
  reducers: {
    resetUpdateNotificationContactState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(updateNotificationContactThunk.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(updateNotificationContactThunk.fulfilled, (state) => {
        state.state = QueryState.CREATED;
      })
      .addCase(updateNotificationContactThunk.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetUpdateNotificationContactState } = updateNotificationContactSlice.actions;
export default updateNotificationContactSlice.reducer;
