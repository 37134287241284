import React, { useRef, useState, useEffect } from 'react';
import { PalmettoSideMenuComponent } from '../../components/primitives';
import { DualTitleHeaderComponent } from '@zawarski/palmetto-ui-components';
import { Checkbox, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { MainLayout } from '../../components/layouts';
import MapView from '@arcgis/core/views/MapView.js';
import { changeMenu, fetchManageColumns, fetchProjectsAndTypes } from '../../store/entities';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { MdList, MdLayers } from 'react-icons/md';
import { BiPrinter } from 'react-icons/bi';
import {
  selectManageColumns,
  selectMapLatLngZoom,
  selectProjectsAndTypes
} from '../../store/selectors';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer.js';
import Graphic from '@arcgis/core/Graphic.js';
import Point from '@arcgis/core/geometry/Point.js';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol.js';
import { resetFetchManageColumns } from '../../store/entities/fetchManageColumns/fetchManageColumnsSlice';
import { QueryState } from '../../common/enums';
import { resetFetchProjectsAndTypes } from '../../store/entities/fetchProjectsAndTypes/fetchProjectsAndTypesSlice';
import { ProjectColors, ProjectTypesList } from 'data';
import { saveMapLatLngZoom } from 'store/entities';
import { resetMapLatLngZoomState } from 'store/entities/mapLatLngZoom/mapLatLngZoomSlice';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import FieldsContent from '@arcgis/core/popup/content/FieldsContent';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import Legend from '@arcgis/core/widgets/Legend';
import WebMap from '@arcgis/core/WebMap';
import LayerList from '@arcgis/core/widgets/LayerList';
import Print from '@arcgis/core/widgets/Print';
import Color from '@arcgis/core/Color';
import Font from '@arcgis/core/symbols/Font';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import LabelClass from '@arcgis/core/layers/support/LabelClass';
import FeatureReductionCluster from '@arcgis/core/layers/support/FeatureReductionCluster';
import { PreLoaderSnippet } from '../../components/snippets';

function MapArcgis() {
  const mapRef = useRef(null);
  const dispatch = useAppDispatch();
  const selectMapLatLngZoomStore = useAppSelector(selectMapLatLngZoom);
  const selectManageColumnsStore = useAppSelector(selectManageColumns);
  const selectProjectsAndTypesStore = useAppSelector(selectProjectsAndTypes);
  const [isHideMarkers, setIsHideMarkers] = useState<boolean>(false);
  const [projectTypes, setProjectTypes] = useState<any[]>([]);
  const [legendEnabled, setLegendEnabled] = useState<boolean>(true);
  const [isMapViewLoading, setIsMapViewLoading] = useState<boolean>(true);
  const mapLatLng = selectMapLatLngZoomStore.mapLatLng;
  const mapZoom = selectMapLatLngZoomStore.mapZoom;

  const isManageColumnsLoading = selectManageColumnsStore.state === QueryState.AWAIT;
  const isSelectProjectsAndTypesLoading = selectProjectsAndTypesStore.state === QueryState.AWAIT;
  const isLoading = isManageColumnsLoading || isSelectProjectsAndTypesLoading || isMapViewLoading;

  const projectTypesToRemoveWhenZoomedIn = [
    'Property Acquisition and Structure Demolition',
    'Property Acquisition and Structure Relocation',
    'Structure Elevation',
    'Mitigation Reconstruction'
  ];

  const getLegend = document.getElementsByClassName(
    'esri-ui-bottom-right esri-ui-corner'
  ) as HTMLCollectionOf<HTMLButtonElement>;
  const getLayerList = document.getElementsByClassName(
    'esri-ui-bottom-left esri-ui-corner'
  ) as HTMLCollectionOf<HTMLButtonElement>;
  // const getPrinter = () => {
  //   const printer = document.getElementsByClassName(
  //       'esri-component esri-print esri-widget esri-widget--panel'
  //   ) as HTMLCollectionOf<HTMLButtonElement>;
  //   return printer;
  // }

  const handleMenu = () => {
    dispatch(changeMenu({ BtnAction: true }));
  };

  const toggleLegend = () => {
    if (getLegend[0] !== null) {
      if (getLegend[0].style.display === 'none') {
        getLegend[0].style.display = 'block';
      } else {
        getLegend[0].style.display = 'none';
      }
    }
  };

  const toggleLayerList = () => {
    if (getLayerList[0] !== null) {
      if (getLayerList[0].style.display === 'none') {
        getLayerList[0].style.display = 'block';
      } else {
        getLayerList[0].style.display = 'none';
      }
    }
  };

  const togglePrinter = () => {
    const printer = document.getElementsByClassName(
      'esri-component esri-print esri-widget esri-widget--panel'
    ) as HTMLCollectionOf<HTMLButtonElement>;
    if (printer[0] !== null) {
      if (printer[0].style.display === 'none') {
        printer[0].style.display = 'block';
      } else {
        printer[0].style.display = 'none';
      }
    }
  };

  const getMarkersLayer = (zoomLevel: number) => {
    const markerGraphics = [];

    // Filter projectTypes based on zoom level
    const filteredProjectTypes = projectTypes.filter((project) => {
      const shouldShowMarker =
        zoomLevel <= 10 || !projectTypesToRemoveWhenZoomedIn.includes(project.project_type);
      return shouldShowMarker;
    });
    //add markers
    for (let i = 0; i < filteredProjectTypes.length; i++) {
      const marker = filteredProjectTypes[i];
      const point = new Point({
        longitude: marker.longitude,
        latitude: marker.latitude
      });

      const graphicPoint = new Graphic({
        geometry: point,
        attributes: {
          OBJECTID: marker.id,
          project_title: marker.project_title,
          applicant_name: marker.applicant_name,
          project_type: marker.project_type,
          hazard_mitigated: marker.hazard_mitigated,
          funding_source: marker.funding_source,
          county: marker.county,
          grant_number: marker.grant_number,
          primary_lifeline_mitigated: marker.primary_lifeline_mitigated,
          projectNumber: marker.projectNumber
        }
      });

      markerGraphics.push(graphicPoint);
    }

    // Create a unique value renderer based on the project type
    const renderer = new UniqueValueRenderer({
      field: 'project_type',
      defaultSymbol: new SimpleMarkerSymbol({
        color: '#888',
        size: 24,
        outline: {
          color: [255, 255, 255],
          width: 3
        }
      }),
      uniqueValueInfos: ProjectTypesList.ProjectTypesList.map((project) => ({
        value: project.value,
        symbol: new SimpleMarkerSymbol({
          color: ProjectColors[project.value] || 'black',
          size: 24,
          outline: {
            color: [255, 255, 255],
            width: 3
          }
        })
      }))
    });

    const markersLayer = new FeatureLayer({
      title: 'Projects',
      source: markerGraphics,
      objectIdField: 'OBJECTID',
      legendEnabled: legendEnabled,
      // maxScale: 250000,
      fields: [
        {
          name: 'OBJECTID',
          type: 'oid'
        },
        {
          name: 'project_title',
          type: 'string'
        },
        {
          name: 'applicant_name',
          type: 'string'
        },
        {
          name: 'project_type',
          type: 'string'
        },
        {
          name: 'hazard_mitigated',
          type: 'string'
        },
        {
          name: 'funding_source',
          type: 'string'
        },
        {
          name: 'primary_lifeline_mitigated',
          type: 'string'
        },
        {
          name: 'county',
          type: 'string'
        },
        {
          name: 'grant_number',
          type: 'string'
        },
        {
          name: 'projectNumber',
          type: 'string'
        }
      ],
      popupTemplate: new PopupTemplate({
        title:
          '<div><div class="popupHeaderText">Grant #{grant_number} Project #{projectNumber}</div><div class="popupSubheaderText">{county}</div></div>',
        content: [
          new FieldsContent({
            fieldInfos: [
              {
                fieldName: 'project_title',
                label: 'Project Title'
              },
              {
                fieldName: 'applicant_name',
                label: 'Applicant Name'
              },
              {
                fieldName: 'project_type',
                label: 'Project Type'
              },
              {
                fieldName: 'hazard_mitigated',
                label: 'Hazard Mitigated'
              },
              {
                fieldName: 'funding_source',
                label: 'Match Source'
              },
              {
                fieldName: 'primary_lifeline_mitigated',
                label: 'Primary Lifeline Mitigated'
              }
            ]
          })
        ]
      }),
      renderer: renderer
    });

    const newFeatureReductionCluster = new FeatureReductionCluster({
      clusterRadius: 100,
      clusterMinSize: 24,
      labelingInfo: [
        new LabelClass({
          deconflictionStrategy: 'static',
          labelExpressionInfo: {
            expression: "Text($feature.cluster_count, '#,###')"
          },
          symbol: new TextSymbol({
            color: new Color([255, 255, 255]), // Adjust the color as needed
            font: new Font({
              family: 'Noto Sans',
              size: 24
            })
          }),
          labelPlacement: 'center-center'
        })
      ]
    });

    markersLayer.featureReduction = newFeatureReductionCluster;
    return markersLayer;
  };

  useEffect(() => {
    dispatch(fetchManageColumns('project'));
    return () => {
      dispatch(resetFetchManageColumns());
    };
  }, []);

  useEffect(() => {
    if (selectManageColumnsStore.state === QueryState.READY) {
      dispatch(fetchProjectsAndTypes(selectManageColumnsStore.manageColumns));
    }
    return () => {
      dispatch(resetFetchProjectsAndTypes());
    };
  }, [selectManageColumnsStore]);

  useEffect(() => {
    if (selectProjectsAndTypesStore.state === QueryState.READY) {
      const projectTypes: any[] = [];
      if (selectProjectsAndTypesStore?.projectsAndTypes?.length > 0) {
        selectProjectsAndTypesStore?.projectsAndTypes.forEach((project: any) => {
          project.projectTypeInfo.forEach((projectType: any) => {
            const countiesArr = project.county.split(',');
            const counties =
              countiesArr.length > 1 ? `${countiesArr.length} counties` : countiesArr.join(', ');
            const projectInfo = {
              id: projectType.id,
              latitude: projectType.latitude,
              longitude: projectType.longitude,
              grant_number: project.grantID,
              county: counties,
              project_title: project.title,
              applicant_name: project.applicant,
              project_type: project.projectTypeName,
              hazard_mitigated: project.primaryHazard,
              funding_source: project.fundingSource,
              primary_lifeline_mitigated: project.primaryLifeline,
              projectId: project.id,
              projectNumber: project.number
            };
            projectTypes.push(projectInfo);
          });
        });
      }
      setProjectTypes(projectTypes);
    }
  }, [selectProjectsAndTypesStore]);

  useEffect(() => {
    if (!mapRef.current || !mapLatLng || !projectTypes?.length || !WebMap) return;

    const map = new WebMap({
      portalItem: {
        id: 'e207cd521f6d4cf2b47394bd71e5a6da'
      }
    });

    map.when(() => {
      setIsMapViewLoading(false);
    });

    const view = new MapView({
      map: map,
      container: mapRef.current,
      center: [mapLatLng[1], mapLatLng[0]],
      zoom: mapZoom,
      ui: {
        components: ['zoom']
      }
    });

    view.when(function () {
      // Move the Zoom widget to the top-right corner
      view.ui.move('zoom', 'top-right');

      const layerList = new LayerList({
        view: view
      });

      view.ui.add(layerList, {
        position: 'bottom-left'
      });

      view.on('layerview-create-error', function (event) {
        console.error('LayerView failed to create for layer with the id: ', event.layer.id);
      });

      const getPopup = () => {
        const popupButton = document.getElementsByClassName(
          'esri-popup__header-container--button'
        ) as HTMLCollectionOf<HTMLButtonElement>;
        if (popupButton.length > 0) {
          popupButton[0].disabled = true;
        }
        return popupButton;
      };

      const markersLayer = getMarkersLayer(mapZoom);

      view.on('click', (event) => {
        setTimeout(() => {
          getPopup();
        }, 500);
      });

      view.map.add(markersLayer);

      const layerInfosArr = [];
      for (let i = 0; i < map.layers.length; i++) {
        const layer = map.layers.getItemAt(i);
        layerInfosArr.push({ layer: layer, title: layer.title });
      }
      // layerInfosArr.push({ layer: markersLayer, title: 'Projects' });
      const legend = new Legend({
        view: view,
        layerInfos: layerInfosArr
      });
      view.ui.add(legend, 'bottom-right');

      //add print service
      const print = new Print({
        view: view,
        printServiceUrl:
          'https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task'
      });

      // Adds widget below other elements in the top left corner of the view
      view.ui.add(print, {
        position: 'top-left'
      });
    });

    return () => {
      if (view) {
        view.destroy();
      }
      if (map) {
        map.destroy();
      }
    };
  }, [mapRef, projectTypes, legendEnabled, WebMap]);

  useEffect(() => {
    return () => {
      dispatch(resetMapLatLngZoomState());
    };
  }, []);

  return (
    <MainLayout styles={{ height: '100%', width: '100%' }} toolbar={<PalmettoSideMenuComponent />}>
      <>
        <PreLoaderSnippet
          visible={isLoading}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '300px'
          }}
          message={'Please wait while we load the data'}
        />
        <div className="layout vertical full-height" hidden={isLoading}>
          <DualTitleHeaderComponent
            icon={
              <IconButton onClick={handleMenu} sx={{ padding: '16px !important' }}>
                <MenuIcon sx={{ color: 'white' }} />
              </IconButton>
            }
            titleBig={'Mitigation'}
            titleSmall={`Map`}
            suffix={
              <div className="layout horizontal">
                <IconButton onClick={togglePrinter}>
                  <BiPrinter className="icon" />
                </IconButton>
                <IconButton onClick={toggleLayerList}>
                  <MdLayers className="icon" />
                </IconButton>
                <IconButton onClick={toggleLegend}>
                  <MdList className="icon" />
                </IconButton>
              </div>
            }
          />

          <div
            id={'toggleHideMarkers'}
            style={{
              display: 'none',
              alignItems: 'center'
            }}>
            <Checkbox
              checked={!isHideMarkers}
              color="primary"
              onClick={(event) => {
                setIsHideMarkers(!isHideMarkers);
              }}
              style={{ padding: '7px' }}
              sx={{
                '& .MuiSvgIcon-root': { fontSize: '22px' },
                '&.Mui-checked': {
                  color: '#6e6e6e'
                },
                '&&:hover': {
                  color: '#949393'
                }
              }}
            />
            <Typography
              color={'textSecondary'}
              variant={'body1'}
              fontWeight={500}
              sx={{ display: 'inline', fontSize: '14px' }}>
              Projects
            </Typography>
          </div>

          <div id="mapContainer" style={{ width: '100%', height: '100%' }}>
            <div
              id="map"
              // data-dojo-type="dijit/layout/ContentPane"
              // data-dojo-props="region:'center'"
              // className="webmap"
              style={{ height: 'calc(100vh - 56px)', top: '56px' }}
              ref={mapRef}
            />
            <div id="legend"></div>
            <div
              id="layerList"
              style={{
                position: 'absolute !important' as any
              }}></div>
          </div>
        </div>
      </>
    </MainLayout>
  );
}

export default MapArcgis;
