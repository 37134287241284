import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { getElementRequirementsCount } from 'services/checklistEndPoints';
import { ICountElemRequirements } from 'interfaces/checklist.interface';

export const fetchCountElemReq = createAsyncThunk('requirement/count', async (id: string) => {
  try {
    const endpoint = getElementRequirementsCount(id);
    const resp = await apiFetch<ICountElemRequirements>(endpoint).then((res) => res?.data || null);
    return resp;
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
