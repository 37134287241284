import { createSlice } from '@reduxjs/toolkit';
import { QueryState } from 'common/enums';
import {
  IRequirementCountState,
  RequirementCountEmptyState
} from './fetchElementRequirementsCountState';
import { fetchCountElemReq } from './fetchElementRequirementsCountThunk';

const initialState: IRequirementCountState = RequirementCountEmptyState;

export const fetchCountElemReqSlice = createSlice({
  name: 'fetchRequirement',
  initialState,
  reducers: {
    resetFetchCountElemReqState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCountElemReq.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchCountElemReq.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.requirementCount = action.payload;
      })
      .addCase(fetchCountElemReq.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchCountElemReqState } = fetchCountElemReqSlice.actions;
export default fetchCountElemReqSlice.reducer;
