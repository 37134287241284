import { AccessTypes } from 'common/enums';

const setAccessType = (accessType: string) => {
  let accessTypeString = '';
  switch (accessType) {
    case 'F':
      accessTypeString = AccessTypes.FULL_ACCESS;
      break;
    case 'L':
      accessTypeString = AccessTypes.LIMITED_ACCESS;
      break;
    case 'G':
      accessTypeString = AccessTypes.GRANT_ACCESS;
      break;
    default:
      break;
  }
  return accessTypeString;
};

export default setAccessType;
