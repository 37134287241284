import { createAsyncThunk } from '@reduxjs/toolkit';
import { IChecklistResponseToSave } from 'interfaces/checklist.interface';
import apiFetch from 'services/apiFetch';
import { updateChecklistResponsesEndpoint } from 'services/checklistEndPoints';

export const updateChecklistResponses = createAsyncThunk(
  'responses/updateChecklistResponses',
  async (data: IChecklistResponseToSave) => {
    try {
      const endpoint = updateChecklistResponsesEndpoint(data);
      return await apiFetch<Partial<IChecklistResponseToSave>>(endpoint).then(
        (res) => res?.data || null
      );
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('updateChecklistResponses catch:', message);
      throw Error(message);
    }
  }
);
