import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { ICountElemRequirements } from 'interfaces/checklist.interface';

export interface IRequirementCountState {
  state: QueryState;
  requirementCount: Nullable<ICountElemRequirements>;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const RequirementCountEmptyState: IRequirementCountState = {
  state: QueryState.UNDEFINED,
  requirementCount: null,
  error: null,
  accessToken: undefined
};
