import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateRequirement } from './updateRequirementThunk';
import { QueryState } from 'common/enums';
import { IUpdateRequirementState, UpdateRequirementEmptyState } from './updateRequirementState';

const initialState: IUpdateRequirementState = UpdateRequirementEmptyState;

export const updateRequirementSlice = createSlice({
  name: 'updateRequirementResponses',
  initialState,
  reducers: {
    resetUpdateRequirementState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(updateRequirement.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(updateRequirement.fulfilled, (state, action) => {
        state.state = QueryState.UPDATED;
      })
      .addCase(updateRequirement.rejected, (state, action) => {
        state.state = QueryState.FAIL;
      });
  }
});

export const { resetUpdateRequirementState } = updateRequirementSlice.actions;
export default updateRequirementSlice.reducer;
