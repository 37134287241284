import DefaultPaletteOptions from 'theming/defaultPalette';

export default (palette: DefaultPaletteOptions): any => ({
  styleOverrides: {
    root: {
      background: palette.primary?.main,
      height: '58px'
    }
  }
});
