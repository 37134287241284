import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { deleteFileEndpoint } from 'services/filesEndPoints';

export const deleteFile = createAsyncThunk('deleteFile', async (id: string) => {
  try {
    const endpoint = deleteFileEndpoint(id);
    return await apiFetch<any>(endpoint).then((res) => res?.data || null);
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
