import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { updateHomeTextEndpoint } from 'services/homeEndPoints';

export const updateHomeText = createAsyncThunk(
  'updateHomeText',
  async ({ homeTextID, payload }: { homeTextID: string; payload: { homepageText: string } }) => {
    try {
      const endpoint = updateHomeTextEndpoint(homeTextID, payload);
      return await apiFetch(endpoint).then((res) => res?.data || null);
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('updateHomeText catch:', message);
      throw Error(message);
    }
  }
);
