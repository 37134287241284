import AuthProtect from 'common/enums/AuthProtect';
import { IEndpoint } from 'interfaces/IEndPoint';
import AES from 'crypto-js/aes';

export const authLogin: (username: string, password: string) => IEndpoint = (
  username: string,
  password: string
) => {
  const passwordKey = process.env.REACT_APP_PASSWORD_KEY;
  if (!passwordKey) {
    throw { status: 400, message: 'Missing password encryption key' };
  }

  return {
    authorization: AuthProtect.NOT_REQUIRED,
    url: 'login',
    method: 'POST',
    data: {
      password: AES.encrypt(password, passwordKey).toString(),
      email: username
    }
  };
};
