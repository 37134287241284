import { ISelectProp } from 'interfaces/SelectProp';

const PlanNotificationMethods: ISelectProp[] = [
  {
    label: 'Email',
    value: 'email'
  }
];

export default PlanNotificationMethods;
