const PrimaryLifelineList = [
  { label: 'Communications', value: 'communications' },
  { label: 'Energy (Power and Fuel)', value: 'energy' },
  { label: 'Food, Water, Shelter', value: 'foodWaterShelter' },
  { label: 'Hazardous Materials', value: 'hazardousMaterials' },
  { label: 'Health and Medical', value: 'healthAndMedical' },
  { label: 'Safety and Security', value: 'safetyAndSecurity' },
  { label: 'Transportation', value: 'transportation' }
];

export default { PrimaryLifelineList };
