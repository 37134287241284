import { createAsyncThunk } from '@reduxjs/toolkit';
import { IFetchFilesPayload, IFileFetch } from 'interfaces/File';
import apiFetch from 'services/apiFetch';
import { getLocalFiles } from 'services/filesEndPoints';
import { getQueryStringFromArr } from 'common/FileLibrary/queryParams';
import { cloneDeep } from 'lodash';

const PARENT_KEY = 'parent'; // Parent Key for datasetByFolder
const FOLDER_MIME_TYPE = 'application/vnd.eti.folder-file';

export const fetchLocalFiles = createAsyncThunk(
  'fetchLocalFiles',
  async (payload: IFetchFilesPayload) => {
    try {
      let dataset;
      const datasetByFolder: any = payload.datasetByFolder
        ? cloneDeep(payload.datasetByFolder)
        : {};

      let parentName;

      if (payload.parent_id) {
        payload.dataset.find((item: IFileFetch) => {
          if (item.pvFolderDataID === payload.parent_id) {
            parentName = item.pvName;
            return true;
          }
          return false;
        });
      }

      if (payload.search) {
        const params = [];
        params.push({ key: 'global_id', value: payload.pvGlobalGroupID });
        params.push({ key: 'search', value: payload.search });
        const queryString = getQueryStringFromArr(params);
        const endpoint = getLocalFiles(queryString);
        const resp = await apiFetch<any>(endpoint).then((res) => res?.data || null);

        dataset = resp.dataset || [];
      } else {
        if (!datasetByFolder[PARENT_KEY]) {
          // Get the Root dataset so that we can determine how to handle group filtering
          const queryString = `?global_id=${payload.pvGlobalGroupID}&mime_type=${FOLDER_MIME_TYPE}`;
          const endpoint = getLocalFiles(queryString);
          const resp = await apiFetch<any>(endpoint).then((res) => res?.data || null);

          const newObj = {
            id: PARENT_KEY,
            name: 'Root',
            files: resp.dataset
          };

          datasetByFolder[PARENT_KEY] = newObj;
          dataset = resp.dataset || [];
          // Also populate the first layer with info we have here
          for (const f of resp.dataset) {
            if (f.pvMIMEType !== FOLDER_MIME_TYPE) continue;
            const obj = {
              id: f.pvFolderDataID,
              name: f.pvName,
              files: null // We don't have these yet.
            };
            datasetByFolder[f.pvFolderDataID] = obj;
          }
        }

        const firstFolder = payload.first_folder_id || PARENT_KEY;

        if (!datasetByFolder[firstFolder]) {
          console.error('No Parent Folder found!', firstFolder, datasetByFolder);
        }

        // Check the current folder, make sure it at least has a files array

        if (
          payload.parent_id
          //   &&
          // datasetByFolder[payload.parent_id] &&
          // !datasetByFolder[payload.parent_id].files
        ) {
          const params = [];

          params.push({ key: 'global_id', value: payload.pvGlobalGroupID });
          params.push({ key: 'parent_id', value: payload.parent_id });
          // if (payload.parent_id) {
          //   params.push({ key: 'parent_id', value: payload.parent_id });
          // } else {
          //   params.push({ key: 'mime_type', value: FOLDER_MIME_TYPE });
          // }
          const queryString = getQueryStringFromArr(params);
          const endpoint = getLocalFiles(queryString);
          const resp = await apiFetch<any>(endpoint).then((res) => res?.data || null);
          const newObj = {
            id: payload.parent_id,
            name: parentName,
            files: resp.dataset
          };

          datasetByFolder[payload.parent_id] = newObj;

          dataset = resp.dataset || [];
        } else {
          if (
            payload?.datasetByFolder &&
            payload?.datasetByFolder[PARENT_KEY] &&
            payload?.datasetByFolder[PARENT_KEY]?.files
          ) {
            dataset = cloneDeep(payload.datasetByFolder[PARENT_KEY].files);
          }
        }
      }

      dataset.sort((a: any, b: any) => {
        const nameA = a.pvName.toLowerCase(),
          nameB = b.pvName.toLowerCase();
        // Make folders appear first
        if (a.pvMIMEType !== b.pvMIMEType && a.pvMIMEType === FOLDER_MIME_TYPE) {
          return nameA.localeCompare(nameB) - 1000;
        } else if (a.pvMIMEType !== b.pvMIMEType && b.pvMIMEType === FOLDER_MIME_TYPE) {
          return nameA.localeCompare(nameB) + 1000;
        } else {
          return nameA.localeCompare(nameB);
        }
      });
      const resp = {
        dataset,
        datasetByFolder,
        parent_id: payload.parent_id
      };
      return resp;
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
