import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProjectDetails } from 'interfaces/project.interface';
import apiFetch from 'services/apiFetch';
import { updateProjectEndpoint } from 'services/projectsEndPoints';

export const updateProject = createAsyncThunk('updateProject', async (data: IProjectDetails) => {
  try {
    const endpoint = updateProjectEndpoint(data);
    return await apiFetch<Partial<IProjectDetails>>(endpoint).then((res) => res?.data || null);
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('updateProject catch:', message);
    throw Error(message);
  }
});
