const ProjectTypesList = [
  { label: '5 Percent Initiative Project', value: '5 Percent Initiative Project' },
  { label: 'Community Safe Room Construction', value: 'Community Safe Room Construction' },
  { label: 'Direct Technical Assistance', value: 'Direct Technical Assistance' },
  {
    label: 'Dry Floodproofing of Historic Residential Structures',
    value: 'Dry Floodproofing of Historic Residential Structures'
  },
  {
    label: 'Dry Floodproofing of Non-Residential Structures',
    value: 'Dry Floodproofing of Non-Residential Structures'
  },
  { label: 'Generator', value: 'Generator' },
  { label: 'Individual Safe Room Construction', value: 'Individual Safe Room Construction' },
  { label: 'Infrastructure Retrofit', value: 'Infrastructure Retrofit' },
  { label: 'Localized Flood Reduction Projects', value: 'Localized Flood Reduction Projects' },
  { label: 'Mitigation Reconstruction', value: 'Mitigation Reconstruction' },
  {
    label: 'Non-Structural Retrofitting of Existing Buildings',
    value: 'Non-Structural Retrofitting of Existing Buildings'
  },
  { label: 'Planning', value: 'Planning' },
  { label: 'Post-Disaster Code Enforcement', value: 'Post-Disaster Code Enforcement' },
  {
    label: 'Property Acquisition and Structure Demolition',
    value: 'Property Acquisition and Structure Demolition'
  },
  {
    label: 'Property Acquisition and Structure Relocation',
    value: 'Property Acquisition and Structure Relocation'
  },
  { label: 'Soil Stabilization', value: 'Soil Stabilization' },
  {
    label: 'Structural Retrofitting of Existing Buildings',
    value: 'Structural Retrofitting of Existing Buildings'
  },
  { label: 'Structure Elevation', value: 'Structure Elevation' },
  { label: 'Wildfire Mitigation', value: 'Wildfire Mitigation' },
  { label: 'Wind Retrofit (w/ Saferoom)', value: 'Wind Retrofit (w/ Saferoom)' }
];

export default { ProjectTypesList };
