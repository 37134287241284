import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { updateElementEndpoint } from 'services/checklistEndPoints';
import { IChecklistUpdateElement } from 'interfaces/checklist.interface';

export const updateElement = createAsyncThunk(
  'responses/updateElement',
  async (data: IChecklistUpdateElement) => {
    try {
      const endpoint = updateElementEndpoint(data);
      return await apiFetch<Partial<IChecklistUpdateElement>>(endpoint).then(
        (res) => res?.data || null
      );
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('updateElement catch:', message);
      throw Error(message);
    }
  }
);
