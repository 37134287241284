import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { updateOrganizationEndpoint } from 'services/plansEndPoints';
import { IUpdateOrganization } from 'interfaces/plan.interface';

export const updateOrganizations = createAsyncThunk(
  'updateOrganizations',
  async (data: IUpdateOrganization) => {
    try {
      const endpoint = updateOrganizationEndpoint(data);
      return await apiFetch<Partial<IUpdateOrganization>>(endpoint).then(
        (res) => res?.data || null
      );
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('updateOrganization catch:', message);
      throw Error(message);
    }
  }
);
