import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';

export interface IUpdateElementState {
  state: QueryState;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const UpdateElementEmptyState: IUpdateElementState = {
  state: QueryState.UNDEFINED,
  error: null,
  accessToken: undefined
};
