import { createAsyncThunk } from '@reduxjs/toolkit';
import { IChecklistDetails, IChecklistElementDetails } from 'interfaces/checklist.interface';
import apiFetch from 'services/apiFetch';
import { getDefaultChecklistElements } from 'services/checklistEndPoints';

export const fetchDefaultChecklistElement = createAsyncThunk('fetchChecklistElement', async () => {
  try {
    const endpoint = getDefaultChecklistElements();
    const resp = await apiFetch<IChecklistElementDetails[]>(endpoint).then(
      (res) => res?.data || null
    );
    return resp;
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
