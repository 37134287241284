import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { IJurisdiction, IJurisdictionDetails } from 'common/interfaces/jurisdiction.interface';
export interface IJurisdictionsState {
  state: QueryState;
  jurisdictions: Nullable<IJurisdictionDetails[]>;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const JurisdictionsEmptyState: IJurisdictionsState = {
  state: QueryState.UNDEFINED,
  jurisdictions: [],
  error: null,
  accessToken: undefined
};
