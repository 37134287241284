import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultRequirementTableState, IRequirementTableState } from './requirementTableViewState';
import { SelectedView } from 'common/enums';
const initialState: IRequirementTableState = DefaultRequirementTableState;

export const requirementTableViewSlice = createSlice({
  name: 'requirementTabView',
  initialState,
  reducers: {
    changeRequirementTabView: (
      state,
      action: PayloadAction<{
        selectedView: SelectedView.ELEMENTS | SelectedView.SUBSECTIONS;
        elementId: string;
        elementIndex: number | null;
      }>
    ) => {
      state.selectedView = action.payload.selectedView;
      state.elementId = action.payload.elementId;
      state.elementIndex = action.payload.elementIndex;
    }
  }
});

export const { changeRequirementTabView } = requirementTableViewSlice.actions;

export default requirementTableViewSlice.reducer;
