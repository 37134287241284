import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../common/enums/AuthProtect';
import { ISendContactMsg } from 'interfaces/notification.interface';

export const sendSupportMsg = (data: ISendContactMsg): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/contact-support',
    data
  };
};
