import React from 'react';
import { DropzoneArea, DropzoneAreaProps } from 'react-mui-dropzone';
import useStyles from './UploadFile.styles';

/*
  You can visit this page for more documentation
  https://www.npmjs.com/package/material-ui-dropzone
  https://yuvaleros.github.io/material-ui-dropzone/
*/

const UploadFile: React.FC<DropzoneAreaProps> = (props) => {
  const classes = useStyles();

  return (
    <DropzoneArea
      {...props}
      classes={{ root: classes.root, text: classes.text }}
      maxFileSize={1099511627776}
      filesLimit={9999999}
      previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
      previewChipProps={{ classes: { root: classes.previewChip } }}
    />
  );
};

export default UploadFile;
