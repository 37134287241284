import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QueryState } from 'common/enums';
import { ICreateInquiry } from 'interfaces/requirements.interface';
import { createChecklistInquiry } from './createInquiryThunk';
import { CreateInquiryEmptyState, ICreateInquiryState } from './createInquiryState';

const initialState: ICreateInquiryState = CreateInquiryEmptyState;

export const createInquirySlice = createSlice({
  name: 'createRequirement',
  initialState,
  reducers: {
    createInquiry: (state, action: PayloadAction<ICreateInquiry>) => {
      state.inquiries = action.payload;
    },
    resetCreateInquiryState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createChecklistInquiry.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(createChecklistInquiry.fulfilled, (state, action) => {
        state.state = QueryState.CREATED;
      })
      .addCase(createChecklistInquiry.rejected, (state, action) => {
        state.state = QueryState.FAIL;
      });
  }
});

export const { resetCreateInquiryState } = createInquirySlice.actions;
export default createInquirySlice.reducer;
