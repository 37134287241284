import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { IFileInfo } from 'interfaces/File';

export interface ICreateFileState {
  state: QueryState;
  file: Nullable<IFileInfo>;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const CreateFileEmptyState: ICreateFileState = {
  state: QueryState.UNDEFINED,
  file: null,
  error: null,
  accessToken: undefined
};
