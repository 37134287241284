import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';
import { IPlanDetails } from 'interfaces/plan.interface';

export interface IFetchPlanState {
  state: QueryState;
  plan: Nullable<IPlanDetails> | undefined;
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const FetchPlanEmptyState: IFetchPlanState = {
  state: QueryState.UNDEFINED,
  plan: null,
  error: null,
  accessToken: undefined
};
