import React from 'react';
import { Box, FormHelperText, Typography } from '@mui/material';
import { FormGroupProps } from './FormGroup.props';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const FormGroup: React.FC<FormGroupProps> = ({
  label,
  color = 'textSecondary',
  error,
  fontWeight = 600,
  variant = 'body1',
  ...props
}: FormGroupProps) => {
  return (
    <Box {...props}>
      <Typography mb="8px" color={color} variant={variant} fontWeight={fontWeight}>
        {label}
      </Typography>
      {props.children}
      <Box
        sx={{
          display: `${error ? 'flex' : 'none'}`,
          alignItems: 'center',
          textAlign: 'center',
          marginBottom: '8px'
        }}>
        <ErrorOutlineOutlinedIcon color="error" sx={{ fontSize: '16px', mr: '6px' }} />
        <FormHelperText error={!!error} sx={{ mt: '4px' }}>
          {error?.message}
        </FormHelperText>
      </Box>
    </Box>
  );
};

export default FormGroup;
