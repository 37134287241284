import { createSlice } from '@reduxjs/toolkit';
import { TimelineEmptyState, ITimelineState } from './fetchTimelineByPlanIdState';
import { fetchTimelineByPlanId } from './fetchTimelineByPlanIdThunk';
import { QueryState } from 'common/enums';

const initialState: ITimelineState = TimelineEmptyState;

export const fetchTimelineByPlanIdSlice = createSlice({
  name: 'fetchTimelineByPlanId',
  initialState,
  reducers: {
    resetFetchTimelineState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTimelineByPlanId.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchTimelineByPlanId.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.timeline = action.payload;
      })
      .addCase(fetchTimelineByPlanId.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchTimelineState } = fetchTimelineByPlanIdSlice.actions;
export default fetchTimelineByPlanIdSlice.reducer;
