import { QueryState } from 'common/enums';
import { Nullable } from 'common/utils/Nullable';

export interface IUpdateProjectState {
  state: QueryState;
  error: Nullable<string>;
  accessToken: string | undefined;
  isEmailDuplicate: boolean;
}

export const UpdateProjectEmptyState: IUpdateProjectState = {
  state: QueryState.UNDEFINED,
  error: null,
  accessToken: undefined,
  isEmailDuplicate: false
};
