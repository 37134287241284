import { createSlice } from '@reduxjs/toolkit';
import { fetchProjectById } from './fetchProjectByIdThunk';
import { QueryState } from 'common/enums';
import { FetchProjectEmptyState, IFetchProjectState } from './fetchProjectByIdState';

const initialState: IFetchProjectState = FetchProjectEmptyState;

export const fetchProjectByIdSlice = createSlice({
  name: 'fetchPlan',
  initialState,
  reducers: {
    resetFetchProjectState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProjectById.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchProjectById.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.project = action.payload;
      })
      .addCase(fetchProjectById.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchProjectState } = fetchProjectByIdSlice.actions;
export default fetchProjectByIdSlice.reducer;
