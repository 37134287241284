import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultMapLatLngZoomState, IMapLatLngZoomState } from './mapLatLngZoomState';

const initialState: any = DefaultMapLatLngZoomState;

export const mapLatLngZoomSlice = createSlice({
  name: 'mapLatLngZoom',
  initialState,
  reducers: {
    resetMapLatLngZoomState: () => {
      return initialState;
    },
    saveMapLatLngZoom: (
      state,
      action: PayloadAction<{ mapLatLng?: [number, number]; mapZoom?: number }>
    ) => {
      if (action.payload.mapLatLng) state.mapLatLng = action.payload.mapLatLng;
      if (action.payload.mapZoom) state.mapZoom = action.payload.mapZoom;
    }
  }
});

export const { resetMapLatLngZoomState, saveMapLatLngZoom } = mapLatLngZoomSlice.actions;

export default mapLatLngZoomSlice.reducer;
