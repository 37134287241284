import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPlanDetails } from 'interfaces/plan.interface';
import apiFetch from 'services/apiFetch';
import { fetchPlanByIdEndpoint } from 'services/plansEndPoints';

export const fetchPlanById = createAsyncThunk('plan/fetchById', async (id: string) => {
  try {
    const endpoint = fetchPlanByIdEndpoint(id);
    const resp = await apiFetch<IPlanDetails>(endpoint).then((res) => res?.data || null);
    resp.poc.forEach((poc) => {
      poc.zipCode = poc.zipCode.toString();
    });
    return resp;
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
