import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateRequirementEmptyState, ICreateRequirementState } from './createRequirementState';
import { QueryState } from 'common/enums';
import { ICreateRequirement } from 'interfaces/requirements.interface';
import { createChecklistRequirement } from './createRequirementThunk';

const initialState: ICreateRequirementState = CreateRequirementEmptyState;

export const createRequirementSlice = createSlice({
  name: 'createRequirement',
  initialState,
  reducers: {
    createRequirement: (state, action: PayloadAction<ICreateRequirement>) => {
      state.requirement = action.payload;
    },
    resetCreateRequirementState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createChecklistRequirement.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(createChecklistRequirement.fulfilled, (state, action) => {
        state.state = QueryState.CREATED;
      })
      .addCase(createChecklistRequirement.rejected, (state, action) => {
        state.state = QueryState.FAIL;
      });
  }
});

export const { resetCreateRequirementState } = createRequirementSlice.actions;
export default createRequirementSlice.reducer;
