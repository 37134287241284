import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { updateTimelineItem } from 'services/timelineEndPoints';
import { ITimelineFormData } from 'pages/Timeline/hooks/useTimelineForm';

export const updateTimeline = createAsyncThunk(
  'timeline/update',
  async (data: ITimelineFormData) => {
    try {
      const endpoint = updateTimelineItem(data);
      return await apiFetch<Partial<ITimelineFormData>>(endpoint).then((res) => res?.data || null);
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('updateTimeline catch:', message);
      throw Error(message);
    }
  }
);
