import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from 'store';
// import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.Fragment>
    <Provider store={store}>
      {/*<PersistGate loading={null} persistor={persistor}>*/}
      <App />
      {/*</PersistGate>*/}
    </Provider>
  </React.Fragment>
);
