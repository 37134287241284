import { AxiosRequestConfig, AxiosRequestHeaders, AxiosHeaders } from 'axios';

const headers = AxiosHeaders.from({
  'Content-Type': 'application/json'
}) as AxiosRequestHeaders;

const config: { headers: AxiosRequestHeaders } & AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API,
  // baseURL to test file upload
  // baseURL: process.env.REACT_APP_PALMETTO_ENDPOINT,
  headers: headers,
  // 'Access-Control-Allow-Origin': '*',
  responseType: 'json'
};

export default config;
