export interface IDocumentsMoveState {
  checkedFiles: number[];
  checkedFolders: string[];
  oldPath: string;
  placeMode: boolean;
  filesToMove: string[];
}

export const DocumentsMoveEmptyState: IDocumentsMoveState = {
  checkedFiles: [],
  checkedFolders: [],
  oldPath: '',
  placeMode: false,
  filesToMove: []
};
