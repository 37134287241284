import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { getDefaultHomeText } from 'services/homeEndPoints';
import { IHomeText } from 'common/interfaces/Home.interface';
export const fetchDefaultHomeText = createAsyncThunk('fetchDefaultHomeText', async () => {
  try {
    const endpoint = getDefaultHomeText();
    const resp = await apiFetch<IHomeText>(endpoint).then((res) => res?.data || null);
    return resp;
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
