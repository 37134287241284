import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100%',
    overflowX: 'hidden',
    backgroundColor: '#e5e5e5'
  },
  page: {
    width: '100%',
    height: 'auto',
    paddingBottom: '50px'
  }
});

export default useStyles;
