import React from 'react';
import { CircularProgress } from '@mui/material';
import MuiButton from '@mui/material/Button';
import { ButtonProps } from './Button.props';
import { styled, CSSObject, SxProps, Theme } from '@mui/material/styles';

import { ButtonStyles } from './Button.styles';

// const StyledButton = styled(MuiButton)(ButtonStyles);

const ProgressIcon = () => <CircularProgress color="inherit" size={22} sx={{ mr: 0.5 }} />;
const Button: React.FC<ButtonProps> = ({
  alternate,
  neutral = true,
  progress = false,
  ...props
}: ButtonProps) => {
  const customStyles = ButtonStyles;
  return (
    <MuiButton
      {...customStyles}
      {...props}
      variant={alternate ? 'outlined' : 'contained'}
      color={neutral ? 'primary' : 'error'}
      startIcon={!progress && props.startIcon}
      endIcon={progress ? <ProgressIcon /> : props.endIcon}
      disableElevation>
      {props.children}
    </MuiButton>
  );
};

export default Button;
