import { createSlice } from '@reduxjs/toolkit';
import { fetchAllProjectsThunk } from './fetchAllProjectsThunk';
import { QueryState } from 'common/enums';
import { FetchProjectsEmptyState, IFetchProjectsState } from './fetchAllProjectsState';

const initialState: IFetchProjectsState = FetchProjectsEmptyState;

export const fetchAllProjectsSlice = createSlice({
  name: 'fetchAllProjects',
  initialState,
  reducers: {
    resetFetchAllProjectsState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllProjectsThunk.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchAllProjectsThunk.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.projects = action.payload?.dataset;
        state.count = action.payload?.count || 0;
      })
      .addCase(fetchAllProjectsThunk.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  }
});

export const { resetFetchAllProjectsState } = fetchAllProjectsSlice.actions;
export default fetchAllProjectsSlice.reducer;
