import AuthProtect from 'common/enums/AuthProtect';
import { IEndpoint } from 'interfaces/IEndPoint';

export const createNotificationContactEndpoint = (data: any): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: 'notificationContact',
    data
  };
};

export const updateNotificationContactEndpoint = (data: any): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: 'notificationContact',
    data
  };
};

export const getContactsAllNotificationsEndpoint = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: 'notificationContact/all'
  };
};

export const deleteNotificationContactEndpoint = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'DELETE',
    url: `notificationContact/${id}`
  };
};
