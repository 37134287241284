import { Nullable } from 'common/utils/Nullable';
import { QueryState } from 'common/enums';
import { IAddTimeline } from 'common/interfaces/timeline.interface';

export interface IAddTimelineState {
  state: QueryState;
  timeline: IAddTimeline[];
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const AddTimelineEmptyState: IAddTimelineState = {
  state: QueryState.UNDEFINED,
  timeline: [],
  error: null,
  accessToken: undefined
};
