//COGNITO
// import axios, { AxiosRequestConfig } from 'axios';
// import merge from 'deepmerge';
// import apiConfig from './apiConfig';
// import AuthProtect from 'common/enums/AuthProtect';
// import { store } from 'store';
// import { IEndpoint } from 'interfaces/IEndPoint';
//
// const genericErrorMessage =
//   'We just experienced a technical issue, please wait a few seconds and try again.';
// function apiFetch<T>(api: IEndpoint): Promise<{ status: number; data: T }> {
//   const headers: any = apiConfig.headers || {};
//   const params: any = api.params || {}; // eslint-disable-line
//   const baseURL = apiConfig.baseURL;
//
//   // Notes: Create API Token to params
//   if (api.authorization === AuthProtect.REQUIRED) {
//     const jwt = store.getState().token.jwToken;
//     if (jwt && headers) {
//       headers.Authorization = jwt;
//     }
//   }
//   const apiConfigMerge: AxiosRequestConfig = merge.all([
//     apiConfig,
//     api,
//     {
//       baseURL,
//       headers,
//       params
//     }
//   ]);
//   const axiosCreate = axios.create(apiConfigMerge);
//
//   axiosCreate.interceptors.response.use(
//     function (response) {
//       return response;
//     },
//     function (error) {
//       return Promise.reject(error);
//     }
//   );
//
//   return axiosCreate({
//     ...apiConfigMerge,
//     validateStatus: (status: number) => {
//       return status < 500;
//     }
//   })
//     .catch((e) => {
//       console.log('apiFetch catch:', e);
//       throw {
//         message: genericErrorMessage
//       };
//     })
//     .then((response) => {
//       if (response.status === 401 || response.status === 403) {
//         localStorage.clear();
//         window.location.href = '/login';
//       }
//
//       return {
//         status: response.status,
//         data: response.data
//       };
//     });
// }
//
// export default apiFetch;

//PALMETTO ACCOUNT
import axios, { AxiosRequestConfig } from 'axios';
import merge from 'deepmerge';
import apiConfig from './apiConfig';
import AuthProtect from 'common/enums/AuthProtect';
import { store } from 'store';
import { IEndpoint } from 'interfaces/IEndPoint';

const genericErrorMessage =
  'We just experienced a technical issue, please wait a few seconds and try again.';
function apiFetch<T>(api: IEndpoint): Promise<{ status: number; data: T }> {
  const headers: AxiosRequestConfig = {};
  const params: any = api.params || {}; // eslint-disable-line
  const baseURL = apiConfig.baseURL;

  // Notes: Create API Token to params
  if (api.authorization === AuthProtect.REQUIRED) {
    const token = store.getState().token.authorization;
    const jwToken = store.getState().token.jwToken;
    if (token) {
      params.access_token = token;
      params.jwToken = jwToken || '';
    }
  }
  const apiConfigMerge: AxiosRequestConfig = merge.all([
    apiConfig,
    api,
    {
      baseURL,
      headers,
      params
    }
  ]);

  const axiosCreate = axios.create(apiConfigMerge);

  axiosCreate.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return axiosCreate({
    ...apiConfigMerge,
    validateStatus: (status: number) => {
      return status < 500;
    }
  })
    .catch((e) => {
      console.log('apiFetch catch:', e);
      throw {
        message: genericErrorMessage
      };
    })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        console.log('response status ', response.status);
      }

      return {
        status: response.status,
        data: response.data
      };
    });
}

export default apiFetch;
