import DefaultPaletteOptions from 'theming/defaultPalette';

export default (palette: DefaultPaletteOptions): any => {
  return {
    styleOverrides: {
      root: { boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.16)!important' },
      rounded: {
        borderRadius: '8px'
      },
      elevation3: {
        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.16)'
      }
    }
  };
};
