import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { getUsersEndpoint } from '../../../services/usersEndPoints';
import { userFiltersSearch } from 'interfaces/userinfo.interface';

export const fetchUsers = createAsyncThunk('users/fetch', async (filters: userFiltersSearch) => {
  try {
    const endpoint = getUsersEndpoint(filters);
    const resp = await apiFetch<any>(endpoint).then((res) => res?.data.dataset || null);
    return resp;
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
