import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { IFileInfo } from 'interfaces/File';
import { createFile } from '../../../services/filesEndPoints';
export const createFileLocally = createAsyncThunk('createFile', async (data: IFileInfo) => {
  try {
    const endpoint = createFile(data);
    return await apiFetch<Partial<IFileInfo>>(endpoint).then((res) => res?.data || null);
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('create File catch:', message);
    throw Error(message);
  }
});
