import { QueryState } from '../../../common/enums';
import { Nullable } from '../../../common/utils';

export interface ITokenState {
  state: QueryState;
  tokenVerification: any;
  error: Nullable<string>;
}

export const TokenEmptyState: ITokenState = {
  state: QueryState.UNDEFINED,
  tokenVerification: null,
  error: null
};
