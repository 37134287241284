import React from 'react';
import { CircularProgress, Stack } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const PreLoaderView: React.FC<{ message?: string; visible: boolean } & BoxProps> = ({
  message = 'Please wait while we load the data',
  visible = false,
  ...props
}) => {
  if (!visible) return null;
  return (
    <Box {...props}>
      <Stack rowGap={2}>
        <CircularProgress size={32} sx={{ mx: 'auto' }} />
        <Typography variant="body1" fontWeight={400} color="textSecondary">
          {message}
        </Typography>
      </Stack>
    </Box>
  );
};

export default PreLoaderView;
