import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentUserEmptyState, ICurrentUserState } from './currentUserState';
import { IUserMitigation } from 'interfaces/userinfo.interface';

const initialState: ICurrentUserState = CurrentUserEmptyState;

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    resetCurrentUserState: () => {
      return initialState;
    },
    updateCurrentUser: (state, action: PayloadAction<Partial<IUserMitigation>>) => {
      state.userAccount = action.payload;
    }
  }
});

export const { resetCurrentUserState, updateCurrentUser } = currentUserSlice.actions;

export default currentUserSlice.reducer;
