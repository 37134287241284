import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from 'services/apiFetch';
import { fetchCountProjectsByTypeEndpoint } from 'services/projectsEndPoints';

export const fetchCountProjectTypes = createAsyncThunk('fetchCountProjectTypes', async () => {
  try {
    const endpoint = fetchCountProjectsByTypeEndpoint();
    const resp = await apiFetch<{ projectType: string; count: number }[]>(endpoint).then(
      (res) => res?.data || null
    );
    return resp;
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
