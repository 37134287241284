import { QueryState } from '../../../common/enums';
import { Nullable } from '../../../common/utils';
import { IProjectTypeInfo } from 'pages/ProjectDetails/hooks/useProjectForm';

export interface IProjectTypesState {
  state: QueryState;
  projectTypes: IProjectTypeInfo[];
  error: Nullable<string>;
  accessToken: string | undefined;
}

export const FetchProjectTypesEmptyState: IProjectTypesState = {
  state: QueryState.UNDEFINED,
  projectTypes: [],
  error: null,
  accessToken: undefined
};
