import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IResponsesState, ChecklistResponsesEmptyState } from './updateChecklistResponsesState';
import { updateChecklistResponses } from './updateChecklistResponsesThunk';
import { QueryState } from 'common/enums';
import { IChecklistResponseToSave } from 'interfaces/checklist.interface';

const initialState: IResponsesState = ChecklistResponsesEmptyState;

export const updateChecklistResponsesSlice = createSlice({
  name: 'updateChecklistResponses',
  initialState,
  reducers: {
    updateResponses: (state, action: PayloadAction<IChecklistResponseToSave[]>) => {
      state.responses = action.payload;
    },
    resetUpdateChecklistResponsesState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(updateChecklistResponses.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(updateChecklistResponses.fulfilled, (state, action) => {
        state.state = QueryState.UPDATED;
      })
      .addCase(updateChecklistResponses.rejected, (state, action) => {
        state.state = QueryState.FAIL;
      });
  }
});

export const { resetUpdateChecklistResponsesState } = updateChecklistResponsesSlice.actions;
export default updateChecklistResponsesSlice.reducer;
