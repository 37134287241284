import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentsMoveEmptyState, IDocumentsMoveState } from './documentsMoveState';

const initialState: IDocumentsMoveState = DocumentsMoveEmptyState;

export const documentsMoveSlice = createSlice({
  name: 'documentsMove',
  initialState,
  reducers: {
    changeDocumentsMoveState: (state, action: PayloadAction<Partial<IDocumentsMoveState>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
    resetDocumentsMoveState: () => {
      return initialState;
    }
  }
});

export const { changeDocumentsMoveState, resetDocumentsMoveState } = documentsMoveSlice.actions;

export default documentsMoveSlice.reducer;
