interface IPlanNotificationHeaders {
  label: 'CONTACT' | 'PHONE#' | 'EMAIL' | 'METHOD' | 'TRIGGER';
  value: 'name' | 'phone' | 'email' | 'method' | 'trigger';
  isActive: boolean;
  size: number;
  weight?: string;
}

const planNotificationsHeaders: IPlanNotificationHeaders[] = [
  {
    label: 'CONTACT',
    value: 'name',
    isActive: true,
    size: 1,
    weight: '700'
  },
  {
    label: 'PHONE#',
    value: 'phone',
    isActive: true,
    size: 1
  },
  {
    label: 'EMAIL',
    value: 'email',
    isActive: true,
    size: 2
  },
  {
    label: 'METHOD',
    value: 'method',
    isActive: true,
    size: 1
  },
  {
    label: 'TRIGGER',
    value: 'trigger',
    isActive: true,
    size: 2
  }
];

export default planNotificationsHeaders;
