import { createSlice } from '@reduxjs/toolkit';
import { UpdatePlanEmptyState, IUpdatePlanState } from './updatePlanState';
import { updatePlan } from './updatePlanThunk';
import { QueryState } from 'common/enums';

const initialState: IUpdatePlanState = UpdatePlanEmptyState;

export const updatePlanSlice = createSlice({
  name: 'updatePlan',
  initialState,
  reducers: {
    resetUpdatePlanState: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(updatePlan.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(updatePlan.fulfilled, (state, action) => {
        state.state = QueryState.UPDATED;
      })
      .addCase(updatePlan.rejected, (state, action) => {
        state.state = QueryState.FAIL;
      });
  }
});

export const { resetUpdatePlanState } = updatePlanSlice.actions;
export default updatePlanSlice.reducer;
