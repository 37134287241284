const setParams = (filters: any): any => {
  let params = { limit: 2000, offset: 0 } as any;
  if (filters.limit) params = { ...params, limit: filters.limit };
  if (filters.offset) params = { ...params, offset: filters.offset };
  const searchFilters = Array.isArray(filters.search)
    ? filters.search
    : filters.search.trim().split(' ');
  // if (filters.search) {
  //   const searchFilters = filters.search.trim().split(' ');
  //   params = { ...params, 'filters[search]': searchFilters };
  // }
  params = { ...params, 'filters[search]': searchFilters };
  if (filters.filters && Object.keys(filters.filters).length > 0) {
    for (const [key, value] of Object.entries(filters.filters)) {
      params = { ...params, [`filters[${key}]`]: value };
    }
  }
  return params;
};

export default setParams;
